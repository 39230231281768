import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Redirect({to=null}){
    const user = useSelector(state => state.user.user);
    const navigate = useNavigate();
  
    useEffect(() => {
      console.log("Redirect component hit:",user)
      if(to){
        navigate(to);
        return;
      }
    },[])

    return (
        // <>Redirecting...</>
        <></>
    )
}