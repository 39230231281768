import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashBoardHeader from "./DashboardHeader";

export default function Admin(){
    const user = useSelector(state => state.user.user);
    const navigate = useNavigate();
  
    useEffect(() => {
      if(user.role != "SUPERADMIN"){
          navigate("/admin/login");
      }
      else{
          navigate("/admin/companies");
      }
    },[])

    return (
        <div>
            
        </div>
    )
}