import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
  } from "react";
  import Grid from "@material-ui/core/Grid";
  import PropTypes from "prop-types";
  import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
  import { gql, useMutation, useQuery } from "@apollo/client";

  import Autocomplete from "@material-ui/lab/Autocomplete";
  import TextField from "@material-ui/core/TextField";
  import {
    DataGrid as DataGridElement,
    GridToolbar,
  } from "@material-ui/data-grid";
  import Edit from "@material-ui/icons/Edit";
  import TrashIcon from "@material-ui/icons/Delete";
  import Button from "@material-ui/core/Button";
  import Select from "@material-ui/core/Select";
  import Input from "@material-ui/core/Input";
  import InputLabel from "@material-ui/core/InputLabel";
  import MenuItem from "@material-ui/core/MenuItem";
  import FormControl from "@material-ui/core/FormControl";
  import ListItemText from "@material-ui/core/ListItemText";
  
  import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { getEditRowData } from "../../redux/reducers/editRowSlice";
import { useDispatch } from "react-redux";
import { config } from "../../config";
import { utils } from "../../utils";
import { IconButton, Modal, Paper, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios";

  const styles = makeStyles((theme) => ({}));
  //Class Designer :
  //Components
  
  const DataGrid_83 = (props) => {
    const [firstCount, setFirstCount] = React.useState(5);
    const [lastCount, setLastCount] = React.useState(5);

    const [modalOpen,setModalOpen] = React.useState(false);
    const [downloadsData,setDownloadsData] = React.useState([]); //template-wise downloads of a user
    const [searchFields, setSearchFields] = React.useState({ company: [] });
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(20);
    const [rows, setRows] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [searchFieldsT, setSearchFieldsT] = React.useState({ company: [] });
    const [sortModel, setSortModel] = useState([
      {
        field: 'id',
        sort: 'desc',
      },
    ]);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [apiData, setData] = React.useState([]);
    
    const gqlQuery = gql`
    query{
      allTemplates{
        Templates{
          id
          company: templatecompanyidmaprel{
            Companies{
              companyname
            }
          }
          language: templatelanguagedidmaprel{
            Languages{
              languagename
            }
          }
          templatemappingTemplatIdMap{
            Templatemappings{
              id
              userid
              templatid
              profileimagexcoordinate
              profileimageycoordinate
              namexcoordinate
              nameycoordinate
              addressxcoordinate
              addressycoordinate
              designationxcoordinate
              designationycoordinate
              phonenumberxcoordinate
              phonenumberycoordinate
              qualificationxcoordinate
              qualificationycoordinate
              starttime
              endtime
            }
          }
          companyid
          languagedid
          templatename
          mediaxcoordinate
          mediaycoordinate
          qualificationxcoordinate
          qualificationycoordinate
          designationxcoordinate
          designationycoordinate
          namexcoordinate
          nameycoordinate
          phonenumberxcoordinate
          phonenumberycoordinate
          addressxcoordinate
          addressycoordinate
          mediawidth
          contentsize
          mediaheight
          filename
          istransparentimage
          useUserTamplateTemplateId{
            totalCount
          }
        }
      }
    }`;

    // const handleSearchItemChange = (e, fieldName, type) => {
    //   let fieldsT = searchFieldsT;
    //   if (type == "DatePicker") {
    //     fieldsT[fieldName] = {
    //       value: moment(e ? (e.target ? e.target.value : e) : e).format(
    //         "yyyy-MM-DD"
    //       ),
    //       type: type,
    //     };
    //   } else {
    //     fieldsT[fieldName] = e ? (e.target ? e.target.value : e) : e;
    //   }
    //   setSearchFieldsT({ ...fieldsT });
    // };
    const getWhereFromSearchFields = () => {
      return JSON.parse(
        "[" +
          Object.entries(searchFields)
            .filter((item) => {
              if (typeof item[1] == "string") {
                return item[1] ? true : false;
              } else if (item[1] instanceof Array) {
                return item[1].length > 0 ? true : false;
              } else {
                return item[1] !== null && typeof (item[1] !== "undefined")
                  ? true
                  : false;
              }
            })
            .map((item) => {
              if (typeof item[1] == "string")
                return '{"' + item[0] + '":{"like":"%' + item[1] + '%"}}';
              else if (item[1] instanceof Array) {
                return (
                  '{"' + item[0] + '":{"inq":' + JSON.stringify(item[1]) + "}}"
                );
              } else if (typeof item[1] == "number")
                return '{"' + item[0] + '":{"like":"%' + item[1] + '%"}}';
              else if (
                typeof item[1] == "object" &&
                item[1].hasOwnProperty("type") &&
                item[1].type == "DatePicker"
              ) {
                let dates = {
                  between: [
                    item[1].value + "T00:00:00.000Z",
                    item[1].value + "T23:59:00.000Z",
                  ],
                };
                return '{"' + item[0] + '":' + JSON.stringify(dates) + "}";
              } else return '{"' + item[0] + '":' + JSON.stringify(item[1]) + "}";
            })
            .join(",") +
          "]"
      );
    };
    const getOrderBy = () => {
      return sortModel
        .map((model) => {
          return model.field + " " + model.sort;
        })
        .join(",");
    };
    const { loading, error, data,fetchMore } = useQuery(gqlQuery);
    
    useEffect(() => {
      localStorage.removeItem("EditRowData");
      dispatch(getEditRowData());
      fetchMore({variables:{}});
  
      if (data) {
        let data_array = [...data.allTemplates.Templates];
        let data_array2 = [];
        console.log(data_array);
        for(let i=0;i<data_array.length;i++){
          data_array2[i] = {...data_array[i]};
          data_array2[i].companyData = data_array[i].company;
          data_array2[i].company = data_array[i].company.Companies[0] ? data_array[i].company.Companies[0].companyname : "";
          data_array2[i].language = data_array[i].language.Languages[0] ? data_array[i].language.Languages[0].languagename : "";
          data_array2[i].Templatemappings = data_array[i].templatemappingTemplatIdMap.Templatemappings.map(
            (item) => Object.assign({},item)
          );
        }
        setRows(data_array2.map((item) => utils.filterUndefined(item)));
        setTotalCount(
          data_array.length
        );
      } else if (!loading) {
        setRows([]);
        setTotalCount(0);
      }
    }, [data]);
  
    const handlePageChange = (params) => {
      // setFirstCount((params.page + 1) * params.pageSize);
      // setLastCount(params.pageSize);
      // setPage(params.page);
      // setPageSize(params.pageSize);
      setPage(params);
    };
    const handlePageSizeChange = (params) => {
      console.log(params);
      setPageSize(params);
      // setFirstCount(params.pageSize);
      // setLastCount(params.pageSize);
      // setPage(0);
      // setPageSize(params.pageSize);
    };
    const handleSortModelChange = (params) => {
      setSortModel(params.sortModel);
    };
    const doSearch = (params) => {
      setSearchFields({
        ...searchFieldsT,
      });
    };
    const doClear = (params) => {
      setSearchFieldsT({ company: [] });
      setSearchFields({});
    };
    const toggleChecked = (field, e) => {
      if (!searchFieldsT[field]) searchFieldsT[field] = true;
      else searchFieldsT[field] = !searchFieldsT[field];
      setSearchFieldsT({ ...searchFieldsT });
    };

    const handleViewDetail = async (templateId) => {
      const token = localStorage.getItem('user')?.token ?? config.accessToken;
      axios.post(config.baseURL + "/graphql",{
        query: `mutation{
          UseusertamplateUseTamplateDoctorname(tamplateId: ${templateId})
        }`
      },{headers:{access_token: token}})
      .then((res) => {
        setDownloadsData(res.data.data.UseusertamplateUseTamplateDoctorname.data);
        setModalOpen(true);
      })
      
    }
  
    let columns = [
      // { field: "id", headerName: "ID", width: 200 },
      // { field: "mediaxcoordinate", headerName: "Media X Coordinate", width: 200 },
      // { field: "mediaycoordinate", headerName: "Media Y Coordinate", width: 200 },
      // { field: "mediawidth", headerName: "Image Width", width: 200 },
      // { field: "mediaheight", headerName: "Image Height", width: 200 },
      // { field: "namexcoordinate", headerName: "Name X Coordinate", width: 200 },
      // { field: "nameycoordinate", headerName: "Name Y Coordinate", width: 200 },
      // { field: "designationxcoordinate", headerName: "Designation X Coordinate", width: 200 },
      // { field: "designationycoordinate", headerName: "Designation Y Coordinate", width: 200 },
      // { field: "qualificationxcoordinate", headerName: "Qualification X Coordinate", width: 200 },
      // { field: "qualificationycoordinate", headerName: "Qualification Y Coordinate", width: 200 },
      // { field: "phonenumberxcoordinate", headerName: "Mobile Number X Coordinate", width: 200 },
      // { field: "phonenumberycoordinate", headerName: "Mobile Number Y Coordinate", width: 200 },
      { field: "templatename", headerName: "Template Name", minWidth: 200, flex: 1 },
      { field: "company", headerName: "Company", minWidth: 200, flex: 1 },
      { field: "language", headerName: "Language", minWidth: 200, flex: 1 },

      { field: "action", headerName: "Actions", minWidth: 200, flex: 1 },
    ].filter((item) => {
      return item.field !== "action";
    });
  
    columns = [
      ...columns,
      {
        headerName:"Content File",
        renderCell: (params) => (
          <a target="_blank" href={`${config.baseURL}/api/Containers/images/download/${params.row.filename}`}>
            {params.row.filename}
          </a>
        ),
        minWidth: 200,
        flex: 1,
        field: "contentfile"
      },
      {
        field: "templateUsedCount",
        headerName:"Template Used Count",
        renderCell: (params) => (
          <div style={{cursor:"pointer"}} onClick={() => {
            handleViewDetail(params.row.id);
          }}>
            <strong>{params.row.useUserTamplateTemplateId.totalCount}</strong>
          </div>

        ),
        minWidth: 200,
        flex: 1,
        field: "contentfile"
      },
      {
        field: "action",
        headerName: "Action",
        minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <strong>
            <Edit
              onClick={() => {
                console.log(params.row);
                localStorage.setItem(
                  "EditRowData",
                  JSON.stringify({ ...params.row })
                );
                dispatch(getEditRowData());
                navigate("/admin/templates/edit");
              }}
              style={{ marginInline: "4px", cursor: "pointer",color:"green" }}
            />
            <TrashIcon
              onClick={() => {
                console.log(params.row);
                swal({
                  title: "Are you sure?",
                  text: "Are you sure you want to delete this template?",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then(willDelete => {
                  if(willDelete)
                    {
                      props.deleteHandler({ id: params.row.id });
                      // window.location.reload();
                    }
                })
              }}
              style={{ marginInline: "4px", cursor: "pointer",color:"red" }}
            />
          </strong>
        ),
      },
    ];
    return (
      <Grid container xs={12} style={{ height: 600 }}>
        <Modal
          open={modalOpen}
          onClose={() => {setModalOpen(false)}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-wrapper"
        >
          <Paper className="modal-body" style={{maxHeight: "400px",overflowY: "scroll"}}>
            <div className="modal-heading-wrapper">
                <IconButton className="modal-close-btn">
                    <CloseIcon onClick={() => {setModalOpen(false)}} />
                </IconButton>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                Template Used Count
                </Typography>
            </div>
            
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <p>
                  <b>Doctor Name</b> &nbsp;&nbsp; <b>Templates used by doctor</b>
                </p>
              {
                downloadsData.length > 0 ? (
                  
                  downloadsData.map((data) => (
                    <p>
                      <b>{data.doctorname} </b> &nbsp;&nbsp; {data.doctorCount}
                    </p>
                  ))
                ) : (
                  <b>No data found</b>
                )
              }
            </Typography>
          </Paper>
        </Modal>
        {/* <Grid container xs={12}>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel id="demo-dropdown-label">Select Company</InputLabel>
              <Select
                labelId="demo-dropdown"
                id="dropdown"
                style={{ minWidth: 230 }}
                value={searchFieldsT.company}
                name="company"
                onChange={(e) => {
                  handleSearchItemChange(e, "company");
                }}
                input={<Input />}
              >
                {[
                  {
                    value: "1",
                    text: "Company1",
                    key: "dropdown_option_35450BDE-BC5B-404E-A1DB-BACDE5E45212",
                  },
                  {
                    value: "2",
                    text: "Company2",
                    key: "dropdown_option_A487B025-CDF2-4164-87DE-CDA2F22385A7",
                  },
                ].map((obj) => {
                  return (
                    <MenuItem key={obj.key} value={obj.value}>
                      {obj.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
  
          <Grid
            item
            xs={4}
            style={{
              display: true ? "flex" : "none",
              flexGrow: "0",
              maxWidth: "33.333333%",
              flexBasis: "16.333333%",
            }}
          >
            <Button
              style={{ margin: "6px" }}
              color="primary"
              variant="contained"
              disabled={loading}
              onClick={doSearch}
              id="gridSearch"
            >
              {loading && (
                <span style={{ color: "white" }}>
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                  />{" "}
                  Search
                </span>
              )}
              {!loading && <span>Search</span>}
            </Button>
          </Grid>
  
          <Grid
            item
            xs={4}
            style={{
              display: true ? "flex" : "none",
              flexGrow: "0",
              maxWidth: "33.333333%",
              flexBasis: "16.333333%",
            }}
          >
            <Button
              style={{ margin: "6px" }}
              color="primary"
              variant="contained"
              disabled={loading}
              onClick={doClear}
              id="gridClear"
            >
              {loading && (
                <span style={{ color: "white" }}>
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                  />{" "}
                  Clear
                </span>
              )}
              {!loading && <span>Clear</span>}
            </Button>
          </Grid>
        </Grid> */}
  
        {/*{error && (<Grid xs={12}>Error! ${error.message}</Grid>)} */}
        <DataGridElement
          columns={columns}
          rows={rows}
          rowCount={totalCount}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
          pageSize={pageSize}
          page={page}
          components={{ Toolbar: GridToolbar }}
          // paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          // sortingMode="server"
          // sortModel={sortModel}
          // onSortModelChange={handleSortModelChange}
          loading={loading}
        />
      </Grid>
    );
  };
  
  const ManageTemplates = (props) => {
    const [fields, setFields] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const [formError, setFormError] = React.useState("");
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [formProcessing, setFormProcessing] = React.useState(false);
    const [loader, setLoader] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [companies, setCompanies] = React.useState([]);

    const createElement = (link, type) => {
      let element = document.createElement("a");
      element.setAttribute("href", link);
      element.setAttribute("download", type);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };
    
    const navigate = useNavigate();
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const gqlQuery = gql`
    query{
      allTemplates{
        Templates{
          id
          userid
          companyid
          templatename
          filename
          mediawidth
          mediaheight
          templatecompanyidmaprel{
            Companies{
              companyname
            }
          }
          templatemappingTemplatIdMap{
            Templatemappings{
              id
              userid
              templatid
              profileimagexcoordinate
              profileimageycoordinate
              namexcoordinate
              nameycoordinate
              addressxcoordinate
              addressycoordinate
              designationxcoordinate
              designationycoordinate
              phonenumberxcoordinate
              phonenumberycoordinate
              qualificationxcoordinate
              qualificationycoordinate
              starttime
              endtime
            }
          }
        }
      }
      allCompanies{
        Companies{
          id
          companyname
        }
      }
      allLanguages{
        Languages{
          id
          languagename
        }
      }
    }`;

    const { data } = useQuery(gqlQuery);

    useEffect(() => {
      if (data) {
        const companydata = data.allCompanies.Companies;
        const languagedata = data.allLanguages.Languages;
        const options = [];
        const options2 = [];
        for(let company of companydata){
          options.push({
            text:company.companyname,
            value:company.id,
            key:company.id,
          });
        }
        for(let language of languagedata){
          options2.push({
            text:language.languagename,
            value:language.id,
            key:language.id,
          });
        }
        // console.log("options",options,options2)
        setCompanies(options);
  
        localStorage.setItem("companies",JSON.stringify(options));
        localStorage.setItem("languages",JSON.stringify(options2));
      }
    },[data])

    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const downloadFile = (path) => {
      setLoader(true);
      const filePath = path;
      fetch(filePath)
        .then((res) => res.blob())
        .then((res) => {
          setLoader(false);
          let url = window.URL.createObjectURL(res);
          let type = res.type;
          createElement(url, type);
        })
        .catch((err) => {
          setLoader(false);
          swal("Something went wrong", {
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          console.log(err);
        });
    };
  
    // const addMoreDataHandler = (item) => {
    //   router.push({ pathname: item });
    // };
    // const handleDialogClose = () => {
    //   setDialogOpen(false);
    //   if (false) {
    //     router.push({ pathname: "undefined" });
    //   }
    //   setFormProcessing(false);
    // };
    // function submitCompleted(data) {
    //   setFormProcessing(true);
    //   setDialogOpen(true);
    // }
  
    const [loading, setLoading] = React.useState(false);
    
    const DELETE_TEMPLATE = gql`mutation saveTemplate($obj:TemplateInput!){
      saveTemplate(obj: $obj){
            id
        }
      }
    `;

    const [deleteTemplateMutation, { data3, error3 }]  = useMutation(DELETE_TEMPLATE);

    const deleteUser = (field) => {
      let fieldsT = {};
      Object.assign(fieldsT, { obj: field.id, active: 0 });
      deleteTemplateMutation({variables:{obj: {id: field.id,active:0}}}).then(() => {
        swal("","Template deleted successfully","success")
        .then(() => {
          window.location.reload();
        })
      })
      .catch((err) => {
        swal("","This template cannot be deleted as it user contains associated user submissions","warning");
      })
    };
    const handleValidation = (fieldname, e) => {
      let errorsT = errors;
      let formIsValid = true;
      let fieldValue = e ? (e.target ? e.target.value : e) : e;
      let updatedValue = fieldValue;
      //company validations
      if (fieldname === undefined || fieldname === "company") {
        if (fieldname === undefined) {
          fieldValue = fields["company"] ? fields["company"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["company"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
          formIsValid = false;
          errorsT["company"] = "Company is required";
        }
      }
  
      setErrors({ ...errorsT });
      return { formIsValid, updatedValue };
    };
  
    const handleChange = (field, e) => {
      const { formIsValid, updatedValue } = handleValidation(field, e);
      let fieldsT = fields;
      fieldsT[field] = updatedValue;
      setFields({ ...fieldsT });
    };
  
    const toggleChecked = (field, e) => {
      let fieldsT = fields;
      if (fieldsT[field] === "") fieldsT[field] = true;
      else fieldsT[field] = !fieldsT[field];
      setFields({ ...fieldsT });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const { formIsValid, updatedValue } = handleValidation();
      if (formIsValid) {
        setDialogOpen(true);
      }
    };
    const deleteHandler = (item) => {
      deleteUser({ id: item.id });
    };
    const { classes } = props;


    return (
      <form>
      
      <div className='main-table-wrapper'>
      
          <Grid container spacing={4}>
                  {/* <Grid item xs={12} >
                  <Autocomplete
                  value ={fields.company}
                  options={companies}
                  getOptionLabel={(option) => option.text ? option.text :""}
                  renderInput={(params) => <TextField {...params} inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }} label="Company" variant="outlined" />}
                  onChange={(event, newValue) => {
                      handleChange("company",newValue?newValue.value:null)
                  }} /><div style={{color: "red"}}>{errors["company"]}</div></Grid> */}
  <DataGrid_83 deleteHandler={deleteHandler} parentState={fields} />
          </Grid>
      </div>
      </form>
  );
  };

  export default ManageTemplates;