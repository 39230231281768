import logo from './logo.svg';
import './App.css';
import Admin from './components/superadmin/Admin';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import AdminLogin from './components/superadmin/AdminLogin';
import { getUser } from './redux/reducers/userSlice';
import ManageCompany from './components/superadmin/manageCompany';
import DashBoardHeader from './components/superadmin/DashboardHeader';
import { Button } from '@material-ui/core';
import AddCompany from "./components/superadmin/AddCompany";
import AddUser from './components/superadmin/AddUser';
import ManageUsers from './components/superadmin/ManageUsers';
import { getEditRowData } from './redux/reducers/editRowSlice';
import Redirect from './components/Redirect';
import ManageTemplates from './components/superadmin/ManageTemplates';
import AddTemplate from './components/superadmin/AddTemplate';
import UserForm from './components/user/UserForm';
import UserLogin from './components/user/UserLogin';
import PageTitle from './components/superadmin/PageTitle';
import ForgotPassword from './components/user/ForgotPassword';
import SubmissionHistory from './components/user/SubmissionHistory';


function Home(){
  const navigate = useNavigate();
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    if(user)
      navigate("/user-form");
    else
      navigate("/login");
  })
  return (
    <div style={{textAlign:"center"}}>
      <h2>PROMEDWISE</h2>
    </div>
  )
}

function App() {

  const user = useSelector(state => state.user.user);
  const EditRowData = useSelector(state => state.EditRowData.EditRowData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(async () => {
    dispatch(getUser());
  },[])

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:code/:email" element={<ForgotPassword showResetPassword={true} />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/user-form" element={<UserForm />} />
        <Route path="/submission-history" element={<SubmissionHistory />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin" element={ user ? <Admin /> : <Redirect to={"/admin/login"} />} />

        <Route path="/admin/companies" element={ user ? (
          <div>
              <DashBoardHeader />
              <div className='page-title-wrapper'>
                <PageTitle pageTitle="Manage Companies" />
                <div style={{textAlign:"right"}} className="page-add-btn">
                    <Link to={"/admin/companies/add"}>
                        <Button color="primary" variant="contained">Add Company</Button>  
                    </Link>
                </div>
              </div>
              <ManageCompany />
          </div>
        ) : <Redirect to={"/admin/login"} /> } /> 
        <Route path="/admin/companies/add" element={ user ? (
          <div style={{textAlign:"center"}}>
              <DashBoardHeader  />
              <PageTitle pageTitle="Add Company" />
              <AddCompany />
          </div>
        ) : <Redirect to={"/admin/login"} />} />
        <Route path="/admin/companies/edit" element={ user ? (
          <div style={{textAlign:"center"}}>
              <DashBoardHeader />
              <PageTitle pageTitle="Edit Company" />
              {
                  EditRowData?.id ? <AddCompany />
                  : <Redirect to='/admin/companies'/>
              }
          </div>
        ) : <Redirect to={"/admin/login"} />} />


        <Route path="/admin/users" element={ user ? (
          <div>
              <DashBoardHeader />
              <div className='page-title-wrapper'>
                <PageTitle pageTitle="Manage Users" />
                <div style={{textAlign:"right"}} className="page-add-btn">
                    <Link to={"/admin/users/add"}>
                        <Button color="primary" variant="contained">Add User</Button>  
                    </Link>
                </div>
              </div>
              <ManageUsers />
          </div>
        ) : <Redirect to={"/admin/login"} />} /> 
        <Route path="/admin/users/add" element={ user ? (
          <div style={{textAlign:"center"}}>
              <DashBoardHeader  />
              <PageTitle pageTitle="Add User" />
              <AddUser />
          </div>
        ) : <Redirect to={"/admin/login"} />} />
        <Route path="/admin/users/edit" element={ user ? (
          <div style={{textAlign:"center"}}>
              <DashBoardHeader />
              <PageTitle pageTitle="Edit User" />
              {
                  EditRowData?.id ? <AddUser />
                  : <Redirect to='/admin/users'/>
              }
          </div>
        ) : <Redirect to={"/admin/login"} />} />


      <Route path="/admin/templates" element={ user ? (
          <div>
              <DashBoardHeader />
              <div className='page-title-wrapper'>
                <PageTitle pageTitle="Manage Templates" />
                <div style={{textAlign:"right"}} className="page-add-btn">
                    <Link to={"/admin/templates/add"}>
                        <Button color="primary" variant="contained">Add Template</Button>  
                    </Link>
                </div>
              </div>
              <ManageTemplates />
          </div>
        ) : <Redirect to={"/admin/login"} />} /> 
        <Route path="/admin/templates/add" element={ user ? (
          <div style={{textAlign:"center"}}>
              <DashBoardHeader  />
              <PageTitle pageTitle="Add Template" />
              <AddTemplate />
          </div>
        ) : <Redirect to={"/admin/login"} />} />
        <Route path="/admin/templates/edit" element={ user ? (
          <div style={{textAlign:"center"}}>
              <DashBoardHeader />
              <PageTitle pageTitle="Edit Template" />
              {
                  EditRowData?.id ? <AddTemplate />
                  : <Redirect to='/admin/users'/>
              }
          </div>
        ) : <Redirect to={"/admin/login"} />} />
      </Routes>
    </>
  );
}

export default App;
