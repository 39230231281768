import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
  } from "react";
  import Grid from "@material-ui/core/Grid";
  import PropTypes from "prop-types";
  import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
  import { gql, useMutation, useQuery } from "@apollo/client";
  
  import TextField from "@material-ui/core/TextField";
  import Button from "@material-ui/core/Button";
  import Dialog from "@material-ui/core/Dialog";
  import DialogContent from "@material-ui/core/DialogContent";
  import DialogContentText from "@material-ui/core/DialogContentText";
  import DialogActions from "@material-ui/core/DialogActions";

  import Select from "@material-ui/core/Select";
  import Input from "@material-ui/core/Input";
  import InputLabel from "@material-ui/core/InputLabel";
  import MenuItem from "@material-ui/core/MenuItem";
  import FormControl from "@material-ui/core/FormControl";
  
  import { useNavigate } from "react-router-dom";
  import swal from "sweetalert";
  import { useSelector } from "react-redux";
  import axios from "axios";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { config } from "../../config";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

  
  const styles = makeStyles((theme) => ({}));
  //Class Designer :
  //Components

  function renameFile(originalFile) {
    const originalName = originalFile.name.replace(" ","_");
    const extension = originalName.split(".")[1];
    const newName = new Date().getTime() + "." +extension;

    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
      });
  }
  
  const AddTemplateInner = (props) => {
    const EditRowData = useSelector((state) => state.EditRowData.EditRowData);
    // console.log("EditRowData :",EditRowData);
    let tempEditRowData = {...EditRowData};
    if(tempEditRowData.companyData){
      if(tempEditRowData.companyData.Companies.length == 0){
        tempEditRowData.companyid = null;
      }
    }
    console.log("tempEditRowData",tempEditRowData)
    
    const [fields, setFields] = React.useState({...tempEditRowData} ?? {});
    const [errors, setErrors] = React.useState({});
    const [timestampError, setTimestampError] = React.useState(false); // do any of timestamps have error
    const [formError, setFormError] = React.useState("");
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [formProcessing, setFormProcessing] = React.useState(false);
    const [loader, setLoader] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [contentFile, setContentFile] = useState(null);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [transparentImage, setTransparentImage] = useState(tempEditRowData.istransparentimage == 1 ? true : false);
  
    const createElement = (link, type) => {
      let element = document.createElement("a");
      element.setAttribute("href", link);
      element.setAttribute("download", type);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };
    
    const navigate = useNavigate();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const trimNumericFields = (data) => {
      if(!data) return 0;
      if(data.length > 1)
        return data.replace(/^0+/, '');
      else return data;
    }

    const ADD_TEMPLATE = gql`mutation{
      TemplateCreateNewTemplate(templateid:null,userid:233,companyid:${fields['companyid']},
        languageid:${fields['languagedid']},templetname:"${fields['templatename']}",
        filename:"${fields['filename']}",
        mediaheight:"${fields['mediaheight']}",mediawidth:"${fields['mediawidth']}",
        contentsize:"${fields['contentsize']}",
        istransparentImage:${transparentImage == false ? 0 : 1},
        multipleTempletArray:[
          {
            id:null,
            starttime:${(fields.Templatemappings && fields.Templatemappings[0]['starttime']) 
            ? trimNumericFields(fields.Templatemappings[0]['starttime']) : 0},
            endtime:${(fields.Templatemappings && fields.Templatemappings[0]['endtime']) 
            ? trimNumericFields(fields.Templatemappings[0]['endtime']) : 0},
            profileimagexcoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['profileimagexcoordinate']) || null},
            profileimageycoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['profileimageycoordinate']) || null},
            namexcoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['namexcoordinate']) || null},
            nameycoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['nameycoordinate']) || null},
            designationxcoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['designationxcoordinate']) || null},
            designationycoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['designationycoordinate']) || null},
            qualificationxcoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['qualificationxcoordinate']) || null},
            qualificationycoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['qualificationycoordinate']) || null},
            phonenumberxcoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['phonenumberxcoordinate']) || null},
            phonenumberycoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['phonenumberycoordinate']) || null},
            addressxcoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['addressxcoordinate']) || null},
            addressycoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['addressycoordinate']) || null},
            active:1
          },
          {
            id:null,
            starttime:${(fields.Templatemappings && fields.Templatemappings[1]['starttime']) 
            ? trimNumericFields(fields.Templatemappings[1]['starttime']) : 0},
            endtime:${(fields.Templatemappings && fields.Templatemappings[1]['endtime']) 
            ? trimNumericFields(fields.Templatemappings[1]['endtime']) : 0},
            profileimagexcoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['profileimagexcoordinate']) || null},
            profileimageycoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['profileimageycoordinate']) || null},
            namexcoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['namexcoordinate']) || null},
            nameycoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['nameycoordinate']) || null},
            designationxcoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['designationxcoordinate']) || null},
            designationycoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['designationycoordinate']) || null},
            qualificationxcoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['qualificationxcoordinate']) || null},
            qualificationycoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['qualificationycoordinate']) || null},
            phonenumberxcoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['phonenumberxcoordinate']) || null},
            phonenumberycoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['phonenumberycoordinate']) || null},
            addressxcoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['addressxcoordinate']) || null},
            addressycoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['addressycoordinate']) || null},
            active:1
          },
      ])
    }`;

    const UPDATE_TEMPLATE = gql`mutation{
      TemplateCreateNewTemplate(templateid:${fields['id']},userid:233,companyid:${fields['companyid']},
        languageid:${fields['languagedid']},templetname:"${fields['templatename']}",filename:"${fields['filename']}",
        mediaheight:"${fields['mediaheight']}",mediawidth:"${fields['mediawidth']}",
        contentsize:"${fields['contentsize']}",
        istransparentImage:${transparentImage == false ? 0 : 1},
        multipleTempletArray:[
          {
            id: ${(fields.templatemappingTemplatIdMap && fields.Templatemappings[0].id) || null},
            starttime:${(fields.Templatemappings && fields.Templatemappings[0]['starttime']) 
            ? trimNumericFields(fields.Templatemappings[0]['starttime']) : 0},
            endtime:${(fields.Templatemappings && fields.Templatemappings[0]['endtime']) 
            ? trimNumericFields(fields.Templatemappings[0]['endtime']) : 0},
            profileimagexcoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['profileimagexcoordinate']) || null},
            profileimageycoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['profileimageycoordinate']) || null},
            namexcoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['namexcoordinate']) || null},
            nameycoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['nameycoordinate']) || null},
            designationxcoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['designationxcoordinate']) || null},
            designationycoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['designationycoordinate']) || null},
            qualificationxcoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['qualificationxcoordinate']) || null},
            qualificationycoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['qualificationycoordinate']) || null},
            phonenumberxcoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['phonenumberxcoordinate']) || null},
            phonenumberycoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['phonenumberycoordinate']) || null},
            addressxcoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['addressxcoordinate']) || null},
            addressycoordinate:${(fields.Templatemappings && fields.Templatemappings[0]['addressycoordinate']) || null},
            active:1
          },
          {
            id:${(fields.templatemappingTemplatIdMap && fields.Templatemappings[1].id) || null},
            starttime:${(fields.Templatemappings && fields.Templatemappings[1]['starttime']) 
            ? trimNumericFields(fields.Templatemappings[1]['starttime']) : 0},
            endtime:${(fields.Templatemappings && fields.Templatemappings[1]['endtime']) 
            ? trimNumericFields(fields.Templatemappings[1]['endtime']) : 0},
            profileimagexcoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['profileimagexcoordinate']) || null},
            profileimageycoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['profileimageycoordinate']) || null},
            namexcoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['namexcoordinate']) || null},
            nameycoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['nameycoordinate']) || null},
            designationxcoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['designationxcoordinate']) || null},
            designationycoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['designationycoordinate']) || null},
            qualificationxcoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['qualificationxcoordinate']) || null},
            qualificationycoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['qualificationycoordinate']) || null},
            phonenumberxcoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['phonenumberxcoordinate']) || null},
            phonenumberycoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['phonenumberycoordinate']) || null},
            addressxcoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['addressxcoordinate']) || null},
            addressycoordinate:${(fields.Templatemappings && fields.Templatemappings[1]['addressycoordinate']) || null},
            active:1
          }
      ])
    }`;

    const [AddTemplateMutation, { data2, error2 }]  = useMutation(ADD_TEMPLATE,{
      onError: (err) => {
        console.warn(err);
      }
    });
    const [updateTemplateMutation, { data3, error3 }]  = useMutation(UPDATE_TEMPLATE,{
      onError: (err) => {
        console.warn(err);
      }
    });
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const downloadFile = (path) => {
      setLoader(true);
      const filePath = path;
      fetch(filePath)
        .then((res) => res.blob())
        .then((res) => {
          setLoader(false);
          let url = window.URL.createObjectURL(res);
          let type = res.type;
          createElement(url, type);
        })
        .catch((err) => {
          setLoader(false);
          swal("Something went wrong", {
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          console.log(err);
        });
    };
  
    // const addMoreDataHandler = (item) => {
    //   router.push({ pathname: item });
    // };
    const handleDialogClose = () => {
      setDialogOpen(false);
      if (false) {
        // router.push({ pathname: "undefined" });
      }
      setFormProcessing(false);
    };
    // function submitCompleted(data) {
    //   setFormProcessing(true);
    //   setDialogOpen(true);
    // }
  
    const [loading, setLoading] = React.useState(false);
  
    // const deletePost = (field) => {
    //   let fieldsT = {};
    //   Object.assign(fieldsT, { id: field.id, active: 0 });
    //   saveMutation({
    //     variables: {
    //       input: fieldsT,
    //     },
    //   });
    // };
    const handleValidation = (fieldname, e) => {
      let errorsT = errors;
      let formIsValid = true;
      let fieldValue = e ? (e.target ? e.target.value : e) : e;
      let fieldValue2 = e ? (e.target ? e.target.value : e) : e;
      let updatedValue = fieldValue;
      let updatedValue2 = fieldValue2;
      
      
      if (fieldname === undefined || fieldname === "companyid") {
        if (fieldname === undefined) {
          fieldValue = fields["companyid"] ? fields["companyid"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["companyid"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
          formIsValid = false;
          errorsT["companyid"] = "Company is required";
        }
      }

      if (fieldname === undefined || fieldname === "languagedid") {
        if (fieldname === undefined) {
          fieldValue = fields["languagedid"] ? fields["languagedid"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["languagedid"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
          formIsValid = false;
          errorsT["languagedid"] = "Language is required";
        }
      }

      if (fieldname === undefined || fieldname === "templatename") {
        if (fieldname === undefined) {
          fieldValue = fields["templatename"] ? fields["templatename"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["templatename"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
          formIsValid = false;
          errorsT["templatename"] = "Template Name is required";
        }
      }

      if (fieldname === undefined || fieldname === "mediawidth") {
        if (fieldname === undefined) {
          fieldValue = fields["mediawidth"] ? fields["mediawidth"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["mediawidth"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
          formIsValid = false;
          errorsT["mediawidth"] = "Image Width is required";
        }
        else{
          if (!(parseFloat(fieldValue) > 0)) {
            formIsValid = false;
            errorsT["mediawidth"] = "Please enter a valid Image Width";
          }
        }
          
      }

      if (fieldname === undefined || fieldname === "mediaheight") {
        if (fieldname === undefined) {
          fieldValue = fields["mediaheight"] ? fields["mediaheight"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["mediaheight"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
          formIsValid = false;
          errorsT["mediaheight"] = "Image Height is required";
        }
        else{
          if (!(parseFloat(fieldValue) > 0)) {
            formIsValid = false;
            errorsT["mediaheight"] = "Please enter a valid Image Height";
          }
        }
      }

      if (fieldname === undefined || fieldname === "contentsize") {
        if (fieldname === undefined) {
          fieldValue = fields["contentsize"] ? fields["contentsize"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["contentsize"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
          formIsValid = false;
          errorsT["contentsize"] = "Content Size is required";
        }
        else{
          if (!(parseFloat(fieldValue) > 0)) {
            formIsValid = false;
            errorsT["contentsize"] = "Please enter a valid Content Size";
          }
        }
          
      }

      setErrors({ ...errorsT });
      return { formIsValid, updatedValue };
    };
  
    const handleChange = (field, e) => {
      console.log("handle change:",field)
      const { formIsValid, updatedValue } = handleValidation(field, e);
      let fieldsT = fields;
      fieldsT[field] = updatedValue;
      setFields({ ...fieldsT });
      // console.log(fieldsT);
    };

    const handleTransparentImage = (field, e) => {
      setTransparentImage(!transparentImage)
      // console.log(fieldsT);
    };
    const handleChangeMapping = (index,field, e) => {
      let errorsT = errors;
      const updatedValue = e.target.value;
      if(field == "starttime"){
        if(parseInt(updatedValue) > parseInt(document.getElementById("endtime"+index).value)){
          errorsT['endtime'+index] = "End time cannot be less than start time";
          console.log("errorsT",errorsT);
          setTimestampError(true);
          setErrors({...errorsT});
        }
        else{
          delete errorsT['endtime'+index];
          setTimestampError(false);
          setErrors({...errorsT});
        }
      }

      if(field == "endtime"){
        if(parseInt(updatedValue) < parseInt(document.getElementById("starttime"+index).value)){
          errorsT['endtime'+index] = "End time cannot be less than start time";
          console.log("errorsT",errorsT);
          setTimestampError(true);
          setErrors({...errorsT});
        }
        else{
          delete errorsT['endtime'+index];
          setTimestampError(false);
          setErrors({...errorsT});
        }
      }

      let fieldsT = {...fields};
      if(fields.Templatemappings)
        fieldsT.Templatemappings = fieldsT.Templatemappings.map((item) => Object.assign({},item));
      else
        fieldsT.Templatemappings = [{},{}];

      fieldsT.Templatemappings[index][field] = updatedValue;
      setFields({ ...fieldsT });
      // console.log(fieldsT);
    };
  
    const toggleChecked = (field, e) => {
      let fieldsT = fields;
      if (fieldsT[field] === "") fieldsT[field] = true;
      else fieldsT[field] = !fieldsT[field];
      setFields({ ...fieldsT });
    };

    // upload content image if present
    const uploadContentImage = async (fileRequired = false) => {
      return new Promise((resolve,reject) => {
        if(!contentFile) {
          if(!fileRequired) resolve("Skipping content file upload");
          else reject("ERROR: No content file provided");
          return;
        }

        const data = new FormData();
        data.append('file',contentFile);
        axios.post(config.baseURL + "/api/Containers/images/upload",data,{
          headers:{
            "Content-type": "multipart/form-data"
          }
        })
        .then(res => {
          if(res.data.error) reject(res.data.error.message);
          console.log("upload result",res.data);
          const filename = res.data.result?.files?.file[0].name;
          let fieldsT = fields;
          fieldsT['filename'] = filename;
          setFields({ ...fieldsT });
          resolve(filename);
        })
        .catch(err => reject(err));
      });
    }
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if(timestampError) return;

      const { formIsValid, updatedValue } = handleValidation();
      if (formIsValid) {
        setUploadInProgress(true);
        uploadContentImage(localStorage.getItem("EditRowData") ? false: true)
        .then(r => {
          setUploadInProgress(false);
          // setDialogOpen(true);
          if(localStorage.getItem("EditRowData")){
              updateTemplateMutation().then(() => {
                swal("","Template updated successfully","success");
                setTimeout(() => {
                  window.location.href = "/#/admin/templates";
                }, 1200);
            })
            .catch(err => {
              // ignore graphql error
              console.warn(err);
            });
          }
          else{
            AddTemplateMutation().then(() => {
              swal("","Template added successfully","success");
                setTimeout(() => {
                  window.location.href = "/#/admin/templates";
                }, 1200);
            }).catch(err => {
              // ignore graphql error
              console.warn(err);
            });
          }
        })
        .catch(err => {
          setUploadInProgress(false);
          console.error(err);
          swal("","Content file is required","warning");
        })
      
      }
      // else{
      //   console.error(errors);
      //   swal("","Please fill all required fields","error");
      // }
    };
    // const deleteHandler = (item) => {
    //   deletePost({ id: item.id });
    // };

    const handleFileInput = (e) => {
      console.log("Original File: ",e.target.files[0]);
      //Check file type
      if(!e.target.files[0].type.includes("image") && !e.target.files[0].type.includes("video") && !e.target.files[0].type.includes("pdf")){
        e.preventDefault();
        swal("","Only image,PDF and video files are allowed","warning");
        document.getElementById("mediafile").value = "";
        return false;
      } 

      // check max file size
      const maxFileSize = 300*1024*1024;
      console.log("file size:",e.target.files[0].size);
      if(e.target.files[0].size > maxFileSize){
        e.preventDefault();
        swal("","Maximum file size is 300 MB","warning");
        document.getElementById("mediafile").value = "";
        return false;
      }

      if(e.target.files[0].type.includes("video")){
        if(!e.target.files[0].type.includes("mp4")){
            e.preventDefault();
            document.getElementById("mediafile").value = "";
            swal("","Only MP4 video files are supported","warning");
            return false;
        }
      }

      if(e.target.files[0].type.includes("gif")){
          e.preventDefault();
          document.getElementById("mediafile").value = "";
          swal("","GIF file is not supported","warning");
          return false;
      } 
      else if(e.target.files[0].type.includes("tiff")){
          e.preventDefault();
          document.getElementById("mediafile").value = "";
          swal("","TIFF file is not supported","warning");
          return false;
      } 
      else if(e.target.files[0].type.includes("webp")){
          e.preventDefault();
          document.getElementById("mediafile").value = "";
          swal("","WEBP file is not supported","warning");
          return false;
      }
      
        
      const file = renameFile(e.target.files[0]);
      console.log("Updated file:",file);
      setContentFile(file);
    }

    const { classes } = props;
    return (
      <form>
      
      <div className="form-wrapper">
      
          <Grid container spacing={4}>

                    <Grid item xs={6}>
                        <FormControl>
                        <InputLabel id="demo-dropdown-label">
                          Company
                          <span aria-hidden="true" class="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span>
                        </InputLabel>
                        <Select
                            labelId="demo-dropdown"
                            id="dropdown"
                            style={{ minWidth: 230,textAlign:"left" }}
                            value={fields.companyid || 0}
                            name="companyid"
                            onChange={(e) => {handleChange("companyid",e)}}
                            onBlur={(e) => {handleChange("companyid",e)}}
                            input={<Input />}
                            required
                        >
                            <MenuItem key={0} value={0}>
                              Select a company
                            </MenuItem>
                            {JSON.parse(localStorage.getItem("companies") ?? "[]").map((obj) => {
                              return (
                                  <MenuItem key={obj.key} value={obj.value}>
                                  {obj.text}
                                  </MenuItem>
                              );
                            })}
                        </Select>
                        </FormControl>
                        <div style={{color: "red"}} className="form-error">{errors["companyid"]}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                        <InputLabel id="demo-dropdown-label">
                          Language 
                          <span aria-hidden="true" class="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span>
                        </InputLabel>
                        <Select
                            labelId="demo-dropdown"
                            id="dropdown"
                            style={{ minWidth: 230,textAlign:"left" }}
                            value={fields.languagedid || 0}
                            name="languageid"
                            onChange={(e) => {handleChange("languagedid",e)}}
                            onBlur={(e) => {handleChange("languagedid",e)}}
                            input={<Input />}
                            required
                        >
                            <MenuItem key={0} value={0}>
                              Select a language
                              </MenuItem>
                            {JSON.parse(localStorage.getItem("languages") ?? "[]")
                            .sort((a, b) => a.text.localeCompare(b.text))
                            .map((obj) => {
                            return (
                                <MenuItem key={obj.key} value={obj.value}>
                                {obj.text}
                                </MenuItem>
                            );
                            })}
                        </Select>
                        </FormControl>
                        <div style={{color: "red"}} className="form-error">{errors["languagedid"]}</div>
                    </Grid>
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='templatename'
                        label='Template Name' 
                        type='text'
                        value={fields.templatename || ''}
                        onChange={(e) => {handleChange("templatename",e)}}
                        onBlur={(e) => {handleChange("templatename",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        required
                        /><div style={{color: "red"}} className="form-error">{errors["templatename"]}</div>
                    </Grid>
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='mediafile'
                        label='Content file (max 300 MB)' 
                        type='file'
                        accept="image/*"
                        // value={fields.mediaxcoordinate || ''}
                        onChange={(e) => {handleFileInput(e)}}
                        // onBlur={(e) => {handleChange("mediafile",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        required={!localStorage.getItem("EditRowData")}
                        /><div style={{color: "red"}} className="form-error">{errors["mediafile"]}</div>
                        
                        {fields.filename !="" && <p style={{textAlign:"left"}}> 
                          <b>Currently uploaded file: </b>
                          <a target="_blank" href={`${config.baseURL}/api/Containers/images/download/${fields.filename}`}>
                            {fields.filename}
                          </a>
                        </p>}
                    </Grid>
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='mediawidth'
                        label='Image Width' 
                        type='number'
                        value={fields.mediawidth || ''}
                        onChange={(e) => {handleChange("mediawidth",e)}}
                        onBlur={(e) => {handleChange("mediawidth",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        required
                        /><div style={{color: "red"}} className="form-error">{errors["mediawidth"]}</div>
                    </Grid>
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='mediaheight'
                        label='Image Height' 
                        type='number'
                        value={fields.mediaheight || ''}
                        onChange={(e) => {handleChange("mediaheight",e)}}
                        onBlur={(e) => {handleChange("mediaheight",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        required
                        /><div style={{color: "red"}} className="form-error">{errors["mediaheight"]}</div>
                    </Grid>
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='contentsize'
                        label='Content Size' 
                        type='number'
                        value={fields.contentsize || ''}
                        onChange={(e) => {handleChange("contentsize",e)}}
                        onBlur={(e) => {handleChange("contentsize",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        required
                        /><div style={{color: "red"}} className="form-error">{errors["contentsize"]}</div>
                    </Grid>
                    <Grid style={{paddingLeft:"15px"}}>
                      <FormControlLabel
                        control={<Checkbox checked={transparentImage }  onChange={(e) => {handleTransparentImage("transparentImage",e)}} />}
                        label="Transparent Background"
                      />
                    </Grid>
                    {/* Individual entry for text coordinates,start and end times */}
                    {
                        [1,2].map((timestampItem,index) => (
                          <Grid container spacing={4} key={index}>
                              <Grid item xs={12}>
                                <b>Time-stamp {index + 1} {index > 0 && "(Used only for video templates)"}</b>
                                <div>
                                  Coordinate values of less than 0 will not be stored 
                                </div>
                              </Grid>
                              <Grid item xs={6} >            
                                  <TextField variant="outlined"
                                  id={'starttime'+index}
                                  label='Start Time (seconds)' 
                                  type='number'
                                  min={0}
                                  value={
                                    fields.Templatemappings && fields.Templatemappings[index]["starttime"] ? 
                                    fields.Templatemappings[index]["starttime"] 
                                    : null
                                  }
                                  onChange={(e) => {handleChangeMapping(index,"starttime",e)}}
                                  onBlur={(e) => {handleChangeMapping(index,"starttime",e)}}
                                  InputLabelProps={{shrink:true}}
                                  InputProps={{
                                      readOnly: false,
                                  }}
                                
                                  />
                              </Grid>
                              <Grid item xs={6} >            
                                  <TextField variant="outlined"
                                  id={'endtime'+index}
                                  label='End Time (seconds)' 
                                  type='number'
                                  min={0}
                                  value={
                                    fields.Templatemappings && fields.Templatemappings[index]["endtime"] ?                           
                                    fields.Templatemappings[index]["endtime"] 
                                    : null
                                  }
                                  onChange={(e) => {handleChangeMapping(index,"endtime",e)}}
                                  onBlur={(e) => {handleChangeMapping(index,"endtime",e)}}
                                  InputLabelProps={{shrink:true}}
                                  InputProps={{
                                      readOnly: false,
                                  }}
                                  
                                  /><div style={{color: "red"}} className="form-error">{errors["endtime" + index]}</div>
                              </Grid>
                              
                              {
                                ["image","name","designation","qualification","phonenumber","address"].map((textfieldVal,t) => (
                                  <React.Fragment key={t}>
                                    <Grid item xs={6}>            
                                        <TextField variant="outlined"
                                        id={textfieldVal.charAt(0).toUpperCase() + textfieldVal.slice(1) + 'xcoordinate'}
                                        label={textfieldVal.charAt(0).toUpperCase() + textfieldVal.slice(1) +' X Coordinate'} 
                                        type='number'
                                        min={0}
                                        value={
                                          fields.Templatemappings && fields.Templatemappings[index]
                                          [(textfieldVal == "image" ? "profileimage" : textfieldVal) + "xcoordinate"] ?  
                                          fields.Templatemappings[index]
                                          [(textfieldVal == "image" ? "profileimage" : textfieldVal) + "xcoordinate"]
                                          : ''
                                        }
                                        onChange={(e) => {handleChangeMapping(index,
                                          (textfieldVal == "image" ? "profileimage" : textfieldVal) + "xcoordinate",e)}}
                                        onBlur={(e) => {handleChangeMapping(index,
                                          (textfieldVal == "image" ? "profileimage" : textfieldVal) + "xcoordinate" ,e)}}
                                        InputLabelProps={{shrink:true}}
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField variant="outlined"
                                        id={textfieldVal.charAt(0).toUpperCase() + textfieldVal.slice(1) + 'ycoordinate'}
                                        label={textfieldVal.charAt(0).toUpperCase() + textfieldVal.slice(1) +' Y Coordinate'} 
                                        type='number'
                                        min={0}
                                        value={
                                          fields.Templatemappings && fields.Templatemappings[index]
                                          [(textfieldVal == "image" ? "profileimage" : textfieldVal) + "ycoordinate"] ?  
                                          fields.Templatemappings[index]
                                          [(textfieldVal == "image" ? "profileimage" : textfieldVal) + "ycoordinate"]
                                          : ''
                                        }
                                        onChange={(e) => {handleChangeMapping(index,
                                          (textfieldVal == "image" ? "profileimage" : textfieldVal) + "ycoordinate",e)}}
                                        onBlur={(e) => {handleChangeMapping(index,
                                          (textfieldVal == "image" ? "profileimage" : textfieldVal) + "ycoordinate" ,e)}}
                                        InputLabelProps={{shrink:true}}
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        />
                                    </Grid>
                                    </React.Fragment>
                                  ))
                                }

                              {/* {index > 0 && (
                              <Grid item xs={12} style={{textAlign:"right"}}>
                                  <Button color="primary" variant="contained">
                                    Add
                                  </Button>
                                
                                <Button size="small" color="secondary" variant="contained">
                                  Remove
                                </Button>
                              </Grid>
                              )} */}
                          </Grid>
                        ))
                    }



                  <Grid item xs={12} >
                  <div style={{ color: "red" }}>{formError}</div>
                      <Button color="primary" variant="contained"  disabled={(loading || formProcessing)}  onClick={(e)=>{handleSubmit(e)}}
                      id='button_A828FCAF7C3F4E1BAE41A2D72318EF6F' >
          {(loading || formProcessing)  && (
              <span style={{color:"white"}}>
              <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
              /> Please Wait...
              </span>
          )}
          {!(loading || formProcessing)  && <span>{typeof(localStorage) !== 'undefined' && localStorage.getItem('EditRowData')?"Update":"Submit"}</span>}
          </Button>
          &nbsp;&nbsp;
          <Button color="primary" style={{backgroundColor: "#d9534f",borderColor: "#d43f3a"}} variant="contained"  onClick={(e)=>{
            window.location.href = "/#/admin/templates";
          }}>Cancel</Button>
          <Dialog
          open={dialogOpen}
          onClose={handleDialogClose} disableEscapeKeyDown={true}
          aria-describedby="alert-dialog-description">
          <DialogContent>
          <div style={{width:"300px"}}>
          <DialogContentText id="alert-dialog-description">
             {"Success!!"}
          </DialogContentText>
          </div>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
              Ok
          </Button>
          </DialogActions>
      </Dialog>
                  </Grid>
          </Grid>
      </div>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={uploadInProgress}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
  );
  };
  AddTemplateInner.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  const CompWithStyleInner = withStyles(styles, { withTheme: true })(
    AddTemplateInner
  );
  function AddTemplate(props) {
    return <CompWithStyleInner props={props}/>;
  }
  export default AddTemplate;