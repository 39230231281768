import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from "./redux/store";
import { ApolloProvider } from '@apollo/client';
import client from "./apollo-client";

import "react-image-gallery/styles/css/image-gallery.css";


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
