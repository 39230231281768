export const utils =  {
    // remove all undefined values from single value or object
    filterUndefined: (inputValue) => {
        if(!inputValue) return "";
        
        let obj = {...inputValue};
        for(let key of Object.keys(inputValue)){
            if(obj[key] === undefined || obj[key] == "undefined") obj[key] = "";
        }

        return obj;
    },
    validateEmail : (email) => {
        return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }
}