// Super admin login page
import {
    Checkbox,
    Grid,
    TextField,
    FormControlLabel,
    Paper,
    Button,
    Typography,
  } from '@material-ui/core';

import { Link,useParams } from 'react-router-dom';

import axios from "axios";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import {config} from "../../config";
import { saveUser } from '../../redux/reducers/userSlice';

export default function ForgotPassword({showResetPassword = false}){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { code,email } = useParams();

    const user = useSelector(state => state.user.user);

    useEffect(() => {
        if(user){
            navigate("/user-form");
        }
    });

    const handleResetPassword = () => {
        // const email = sessionStorage.getItem("resetpassword-email");
        if(!email || email == "") {
            swal("","Invalid email","error");
            navigate("/forgot-password");
            return;
        }
        
        const password = document.getElementById("newpassword").value;
        const confirmpassword = document.getElementById("confirmpassword").value;

        if(password == "" || confirmpassword == ""){
            swal("","New Password and Confirm Password cannot be blank","warning");
            return;
        }

        if(password != confirmpassword) {
            swal("","New Password and Confirm Password must be same","warning");
            return;
        }

        axios.post(`${config.baseURL}/resetPassword?email=${email}&tempPassword=${code ?? "NOCODE"}&newPassword=${password}`)
        .then(res => {
            if(res.data.status == 200){
                sessionStorage.removeItem("resetpassword-email");
                swal("","Password reset successfully","success");
                navigate("/");
            }
            else{
                swal("","Reset password link is invalid. Please re-generate the link.","error");
                navigate("/forgot-password");
            }
        })
    }

    const handleResetPasswordClick = () => {
        const email = document.getElementById("admin_email").value;
        axios.post(`${config.baseURL}/forgotPassword?email=${email}`)
        .then(res => {
            if(res.data.status == 200){
                sessionStorage.setItem("resetpassword-email",email);
                swal("",res.data.message,"success")
                .then(r => {
                    navigate("/login");
                })
            }
            else{
                swal("","Please enter your registered email id","warning");
            }
        })
            
    }
    const handleValidate = (e) => {
        
    }

    return (
        <div className='login-page-wrapper user-login-page'>
            
            <div className="logo-wrapper">
                <Link to='/' className="logo-link">
                    <img src="../../../promedwise-logo.png" alt="Promedwise Logo" width="200px"/>
                </Link>
            </div>
            
            {
                !showResetPassword &&
                <Paper>
                    <Typography style={{fontSize:25}} align='center'>Forgot Password</Typography> <br />
                    <Grid
                    container
                    spacing={3}
                    direction={'column'}
                    justify={'center'}
                    alignItems={'center'}
                    >
                        <Grid item lg={12}>
                            <TextField id='admin_email' label="Email" />
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "center"}}>
                            <Button onClick={handleResetPasswordClick} variant='contained' color="primary" fullWidth> Send Link </Button>
                        </Grid>
                    </Grid>
                </Paper>
            }
            {
                showResetPassword && 
                <Paper>
                    <Typography style={{fontSize:25}} align='center'>Reset Password</Typography> <br />
                    <Grid
                    container
                    spacing={3}
                    direction={'column'}
                    justify={'center'}
                    alignItems={'center'}
                    >
                        <Grid item lg={12}>
                            <TextField type="password" onKeyDown={handleValidate} id='newpassword' label="New Password" />
                            <TextField type="password" onKeyDown={handleValidate} id='confirmpassword' label="Confirm Password" />
                            <TextField style={{display:"none"}} id='otp' label="Code" />
                        </Grid>

                        <Grid item xs={12} style={{textAlign: "center"}}>
                            <Button onClick={handleResetPassword} variant='contained' color="primary" fullWidth> Reset Password </Button>
                        </Grid>
                    </Grid>
                </Paper>
            }

            <div className='footer-link'>
                <Link to="">www.promedwise.com</Link>
            </div>
        </div>
    );
}