import { Link } from "@material-ui/core";
import { Link as Link2 } from "react-router-dom";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { gql, useMutation, useQuery } from "@apollo/client";

import {
  DataGrid as DataGridElement,
  GridToolbar,
} from "@material-ui/data-grid";
import Edit from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEditRowData } from "../../redux/reducers/editRowSlice";
import { utils } from "../../utils";
import { config } from "../../config";

const DataGrid_25 = ({ user }) => {
  const [firstCount, setFirstCount] = React.useState(5);
  const [lastCount, setLastCount] = React.useState(5);
  const [searchFields, setSearchFields] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(20);
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchFieldsT, setSearchFieldsT] = React.useState({});
  const [sortModel, setSortModel] = useState([
  ]);
  let navigate = useNavigate();
  const [apiData, setData] = React.useState([]);

  const dispatch = useDispatch();

  const gqlQuery = gql`
    mutation{
        UseusertamplateGetUserTemplateHistory(userid:${user.id})
    }`;


  const [loadSubmissions, { data2, eror }] = useMutation(gqlQuery);
  // const data2 = JSON.parse(`{
  //     "data": {
  //       "UseusertamplateGetUserTemplateHistory": {
  //         "status": 200,
  //         "data": [
  //           {
  //             "id": 386,
  //             "userid": 298,
  //             "templateid": 62,
  //             "languageid": null,
  //             "companyid": null,
  //             "name": "Shreyak QSS",
  //             "designation": "Developer",
  //             "qualification": "MCA",
  //             "address": "1 Main st.",
  //             "phonenumber": "9987776543",
  //             "createdtemplateurl": "edited-1652847580010.mp4",
  //             "profileimagename": "1652847563998.png",
  //             "active": 1,
  //             "createdBy": null,
  //             "updatedBy": null,
  //             "createdOn": "2022-05-18T04:19:32.000Z",
  //             "updatedOn": "2022-05-18T04:19:32.000Z",
  //             "ismediagenerated": 1,
  //             "ismediaprocessing": 1,
  //             "firstName": "test",
  //             "lastName": "test",
  //             "templatename": "Hair Product "
  //           },
  //           {
  //             "id": 387,
  //             "userid": 298,
  //             "templateid": 62,
  //             "languageid": null,
  //             "companyid": null,
  //             "name": "Shreyak QSS",
  //             "designation": "Developer",
  //             "qualification": "MCA",
  //             "address": "1 Main st",
  //             "phonenumber": "9987776543",
  //             "createdtemplateurl": "edited-1652848060008.mp4",
  //             "profileimagename": "1652847563998.png",
  //             "active": 1,
  //             "createdBy": null,
  //             "updatedBy": null,
  //             "createdOn": "2022-05-18T04:27:34.000Z",
  //             "updatedOn": "2022-05-18T04:27:34.000Z",
  //             "ismediagenerated": 1,
  //             "ismediaprocessing": 1,
  //             "firstName": "test",
  //             "lastName": "test",
  //             "templatename": "Hair Product "
  //           },
  //           {
  //             "id": 389,
  //             "userid": 298,
  //             "templateid": 62,
  //             "languageid": null,
  //             "companyid": null,
  //             "name": "Shreyak QSS",
  //             "designation": "Developer",
  //             "qualification": "MCA",
  //             "address": "1 Main st.",
  //             "phonenumber": "9987776543",
  //             "createdtemplateurl": "edited-1652848350008.mp4",
  //             "profileimagename": "1652847563998.png",
  //             "active": 1,
  //             "createdBy": null,
  //             "updatedBy": null,
  //             "createdOn": "2022-05-18T04:32:20.000Z",
  //             "updatedOn": "2022-05-18T04:32:20.000Z",
  //             "ismediagenerated": 1,
  //             "ismediaprocessing": 1,
  //             "firstName": "test",
  //             "lastName": "test",
  //             "templatename": "Hair Product "
  //           },
  //           {
  //             "id": 392,
  //             "userid": 298,
  //             "templateid": 62,
  //             "languageid": null,
  //             "companyid": null,
  //             "name": "Shreyak QSS",
  //             "designation": "Developer",
  //             "qualification": "MCA",
  //             "address": "1 Main st.",
  //             "phonenumber": "9987776543",
  //             "createdtemplateurl": "edited-1652849260014.mp4",
  //             "profileimagename": "1652849248789.png",
  //             "active": 1,
  //             "createdBy": null,
  //             "updatedBy": null,
  //             "createdOn": "2022-05-18T04:47:36.000Z",
  //             "updatedOn": "2022-05-18T04:47:36.000Z",
  //             "ismediagenerated": 1,
  //             "ismediaprocessing": 1,
  //             "firstName": "test",
  //             "lastName": "test",
  //             "templatename": "Hair Product "
  //           },
  //           {
  //             "id": 393,
  //             "userid": 298,
  //             "templateid": 62,
  //             "languageid": null,
  //             "companyid": null,
  //             "name": "Shreyak QSS",
  //             "designation": "Developer",
  //             "qualification": "MCA",
  //             "address": "1 Main st.",
  //             "phonenumber": "9987776543",
  //             "createdtemplateurl": "edited-1652849320011.mp4",
  //             "profileimagename": "1652849313519.png",
  //             "active": 1,
  //             "createdBy": null,
  //             "updatedBy": null,
  //             "createdOn": "2022-05-18T04:48:39.000Z",
  //             "updatedOn": "2022-05-18T04:48:39.000Z",
  //             "ismediagenerated": 1,
  //             "ismediaprocessing": 1,
  //             "firstName": "test",
  //             "lastName": "test",
  //             "templatename": "Hair Product "
  //           },
  //           {
  //             "id": 394,
  //             "userid": 298,
  //             "templateid": 62,
  //             "languageid": null,
  //             "companyid": null,
  //             "name": "Shreyak QSS",
  //             "designation": "Developer",
  //             "qualification": "MCA",
  //             "address": "1 Main st.",
  //             "phonenumber": "9987776543",
  //             "createdtemplateurl": "edited-1652852130009.mp4",
  //             "profileimagename": "1652852120362.png",
  //             "active": 1,
  //             "createdBy": null,
  //             "updatedBy": null,
  //             "createdOn": "2022-05-18T05:35:29.000Z",
  //             "updatedOn": "2022-05-18T05:35:29.000Z",
  //             "ismediagenerated": 1,
  //             "ismediaprocessing": 1,
  //             "firstName": "test",
  //             "lastName": "test",
  //             "templatename": "Hair Product "
  //           },
  //           {
  //             "id": 396,
  //             "userid": 298,
  //             "templateid": 62,
  //             "languageid": null,
  //             "companyid": null,
  //             "name": "Shreyak QSS",
  //             "designation": "Developer",
  //             "qualification": "MCA",
  //             "address": "1 Main st.",
  //             "phonenumber": "9987776543",
  //             "createdtemplateurl": "edited-1653022760018.mp4",
  //             "profileimagename": "1653022752249.png",
  //             "active": 1,
  //             "createdBy": null,
  //             "updatedBy": null,
  //             "createdOn": "2022-05-20T04:59:19.000Z",
  //             "updatedOn": "2022-05-20T04:59:19.000Z",
  //             "ismediagenerated": 1,
  //             "ismediaprocessing": 1,
  //             "firstName": "test",
  //             "lastName": "test",
  //             "templatename": "Hair Product "
  //           },
  //           {
  //             "id": 401,
  //             "userid": 298,
  //             "templateid": 77,
  //             "languageid": null,
  //             "companyid": null,
  //             "name": "Test Product",
  //             "designation": "Doctor",
  //             "qualification": "MBBS",
  //             "address": "",
  //             "phonenumber": "0987654321111",
  //             "createdtemplateurl": "edited-1653449191158.pdf",
  //             "profileimagename": "1653447980605.jpg",
  //             "active": 1,
  //             "createdBy": null,
  //             "updatedBy": null,
  //             "createdOn": "2022-05-25T03:06:56.000Z",
  //             "updatedOn": "2022-05-25T03:06:56.000Z",
  //             "ismediagenerated": 1,
  //             "ismediaprocessing": 0,
  //             "firstName": "test",
  //             "lastName": "test",
  //             "templatename": "Face Product"
  //           },
  //           {
  //             "id": 402,
  //             "userid": 298,
  //             "templateid": 62,
  //             "languageid": null,
  //             "companyid": null,
  //             "name": "Test",
  //             "designation": "Doctor",
  //             "qualification": "MBBS",
  //             "address": "1st street address",
  //             "phonenumber": "0987654321",
  //             "createdtemplateurl": "edited-1653448235175.mp4",
  //             "profileimagename": "1653448202735.jpg",
  //             "active": 1,
  //             "createdBy": null,
  //             "updatedBy": null,
  //             "createdOn": "2022-05-25T03:10:29.000Z",
  //             "updatedOn": "2022-05-25T03:10:29.000Z",
  //             "ismediagenerated": 1,
  //             "ismediaprocessing": 1,
  //             "firstName": "test",
  //             "lastName": "test",
  //             "templatename": "Hair Product "
  //           },
  //           {
  //             "id": 410,
  //             "userid": 298,
  //             "templateid": 83,
  //             "languageid": null,
  //             "companyid": null,
  //             "name": "Shreyak QSS",
  //             "designation": "Developer",
  //             "qualification": "MCA",
  //             "address": "1 Main st.",
  //             "phonenumber": "9987776543",
  //             "createdtemplateurl": "edited-1653464180009.jpg",
  //             "profileimagename": "1653464168352.png",
  //             "active": 1,
  //             "createdBy": null,
  //             "updatedBy": null,
  //             "createdOn": "2022-05-25T07:36:14.000Z",
  //             "updatedOn": "2022-05-25T07:36:14.000Z",
  //             "ismediagenerated": 1,
  //             "ismediaprocessing": 0,
  //             "firstName": "test",
  //             "lastName": "test",
  //             "templatename": "JJJ"
  //           },
  //           {
  //             "id": 411,
  //             "userid": 298,
  //             "templateid": 62,
  //             "languageid": null,
  //             "companyid": null,
  //             "name": "Shreyak QSS",
  //             "designation": "",
  //             "qualification": "",
  //             "address": "1 Main st.",
  //             "phonenumber": "9987776543",
  //             "createdtemplateurl": null,
  //             "profileimagename": "1653464439241.png",
  //             "active": 1,
  //             "createdBy": null,
  //             "updatedBy": null,
  //             "createdOn": "2022-05-25T07:40:45.000Z",
  //             "updatedOn": "2022-05-25T07:40:45.000Z",
  //             "ismediagenerated": 0,
  //             "ismediaprocessing": 1,
  //             "firstName": "test",
  //             "lastName": "test",
  //             "templatename": "Hair Product "
  //           }
  //         ]
  //       }
  //     }
  //   }`).data;

  useEffect(() => {
    localStorage.removeItem("EditRowData");
    dispatch(getEditRowData());
    //   fetchMore({variables:{}});
    let data = null;
    if (user) {
      loadSubmissions().then(res => {
        console.log(res);
        data = res.data;
        if (data) {
          setRows(data.UseusertamplateGetUserTemplateHistory.data.map((item) => utils.filterUndefined(item)));
          setTotalCount(
            data.UseusertamplateGetUserTemplateHistory.data.length
          );
        } else {
          setRows([]);
          setTotalCount(0);
        }
      })
    }
    else {
      setRows([]);
      setTotalCount(0);
    }

  }, []);

  const handlePageChange = (params) => {
    // setFirstCount((params.page + 1) * params.pageSize);
    // setLastCount(params.pageSize);
    // setPage(params.page);
    // setPageSize(params.pageSize);
    setPage(params);
  };
  const handlePageSizeChange = (params) => {
    console.log(params);
    setPageSize(params);
    // setFirstCount(params.pageSize);
    // setLastCount(params.pageSize);
    // setPage(0);
    // setPageSize(params.pageSize);
  };

  const downloadTemplate = (templateurl,templateDetails) =>{
    console.log("templateDetailstemplateDetails",templateDetails)
    let fileName = config.baseURL+"/api/Containers/images/download/" + templateurl
    fetch(fileName, { method: "GET", mode: 'cors' }).then(res => res.blob())
    .then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = templateurl   //fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
    })
  }
  const handleSortModelChange = (params) => {
    setSortModel(params.sortModel);
  };

  let columns = [
    { field: "templatename", headerName: "Name", minWidth: 200, flex: 1 },
    {
      field: "datetime",
      headerName: "Date/Time",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <>
          {new Date(params.row.createdOn).toLocaleString()}
        </>
      )
    },
    {
      field: "doctor Name",
      headerName: "Doctor Name",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.name}
        </>
      )
    },
    //   { field: "ismediagenerated", headerName: "Status", width: 200,flex: 1 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.ismediagenerated == 1 && params.row.ismediaprocessing == 1 ? "Completed" : "Pending"}
          {params.row.ismediagenerated == 1 && params.row.ismediaprocessing == 1 &&
            <Button onClick={() => { downloadTemplate(params.row.createdtemplateurl,params.row) }} variant="contained" color="primary" style={{ marginLeft: "0.5rem" }} className="download-btn">
              Download
            </Button>
          }
        </>
      )
    }
  ];


  return (
    <Grid container xs={12} style={{ height: 600 }}>
      {/*{error && (<Grid xs={12}>Error! ${error.message}</Grid>)} */}
      <DataGridElement
        columns={columns}
        rows={rows}
        rowCount={totalCount || 0}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
        pageSize={pageSize}
        page={page}
        components={{ Toolbar: GridToolbar }}
        // paginationMode="server"
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        // sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
      //   loading={loading}
      />
    </Grid>
  );
};
export default function SubmissionHistory() {
  const user = useSelector(state => state.user.user);
  return (
    <>
      <div className="header-wrapper">
        <div className="logo-wrapper">
          <Link to='/' className="logo-link">
            <img src="../../../promedwise-logo.png" alt="Promedwise Logo" width="100px" />
          </Link>
        </div>
      </div>

      <div class="page-title-wrapper">
        <div class="page-title">
          <h2>Template History</h2>
        </div>
        <div className="page-add-btn">
          <Link2 to={"/user-form"}>
            <Button color="default" variant="contained">Go back</Button>
          </Link2>
        </div>
      </div>

      <div className='main-table-wrapper'>
        {user && <Grid container spacing={4}>
          <DataGrid_25 user={user} />
        </Grid>}
      </div>
    </>
  )
}