import React from 'react';

import { Box } from '@material-ui/core';

export default function PageTitle({pageTitle}){
    return (
        <Box>
            <div className="page-title">
                <h2>{pageTitle}</h2>
            </div>
        </Box>
    );
}