import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
  } from "react";
  import Grid from "@material-ui/core/Grid";
  import PropTypes from "prop-types";
  import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
  import { gql, useMutation, useQuery } from "@apollo/client";
 
  import {
    DataGrid as DataGridElement,
    GridToolbar,
  } from "@material-ui/data-grid";
  import Edit from "@material-ui/icons/Edit";
  import TrashIcon from "@material-ui/icons/Delete";
  import TextField from "@material-ui/core/TextField";
  import Button from "@material-ui/core/Button";
  import Select from "@material-ui/core/Select";
  import Input from "@material-ui/core/Input";
  import InputLabel from "@material-ui/core/InputLabel";
  import MenuItem from "@material-ui/core/MenuItem";
  import FormControl from "@material-ui/core/FormControl";
  import ListItemText from "@material-ui/core/ListItemText";
  import Receipt from '@material-ui/icons/Receipt';
  
  import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEditRowData } from "../../redux/reducers/editRowSlice";
import { utils } from "../../utils";
import axios from "axios";
import { config } from "../../config";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
  
  const styles = makeStyles((theme) => ({}));
  //Class Designer :
  //Components
  
  const DataGrid_25 = (props) => {
    const [firstCount, setFirstCount] = React.useState(5);
    const [lastCount, setLastCount] = React.useState(5);
    const [searchFields, setSearchFields] = React.useState({});
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(20);
    const [rows, setRows] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [searchFieldsT, setSearchFieldsT] = React.useState({});
    const [sortModel, setSortModel] = useState([
      {
        field: 'id',
        sort: 'desc',
      },
    ]);
    let navigate = useNavigate();
    const [apiData, setData] = React.useState([]);

    const dispatch = useDispatch();
  
    const gqlQuery = gql`
    query{
      allCompanies{
        Companies{
          id
          companyname
          companyaddress
          companyemailid
          companymobilenumber
          companystate
          companycity
          active
        }
      }
    }`;
  

    const { loading, error, data,fetchMore } = useQuery(gqlQuery);
  
    useEffect(() => {
      localStorage.removeItem("EditRowData");
      dispatch(getEditRowData());
      fetchMore({variables:{}});
      
      if (data) {
        setRows(data.allCompanies.Companies.map((item) => utils.filterUndefined(item)));
        setTotalCount(
          data.allCompanies.Companies.length
        );
      } else if (!loading) {
        setRows([]);
        setTotalCount(0);
      }
    }, [data]);
  
    const handlePageChange = (params) => {
      // setFirstCount((params.page + 1) * params.pageSize);
      // setLastCount(params.pageSize);
      // setPage(params.page);
      // setPageSize(params.pageSize);
      setPage(params);
    };
    const handlePageSizeChange = (params) => {
      console.log(params);
      setPageSize(params);
      // setFirstCount(params.pageSize);
      // setLastCount(params.pageSize);
      // setPage(0);
      // setPageSize(params.pageSize);
    };
    const handleSortModelChange = (params) => {
      setSortModel(params.sortModel);
    };
    const doSearch = (params) => {
      setSearchFields({
        ...searchFieldsT,
      });
    };
    const doClear = (params) => {
      setSearchFieldsT({});
      setSearchFields({});
    };
    const toggleChecked = (field, e) => {
      if (!searchFieldsT[field]) searchFieldsT[field] = true;
      else searchFieldsT[field] = !searchFieldsT[field];
      setSearchFieldsT({ ...searchFieldsT });
    };

   const setDataWithHeader = async(fileData,type) => {
    console.log("=======>")
      let filedata = [];
      if(type=="data"){
          fileData
              ? fileData.map((item, index) => {
                  return filedata.push({
                      "Doctor Name": item.doctor_name,
                      "Template Name": item.template_name,
                      "Email id": item.Email_id,
                      "Downloaded By User": item.DownloadedUser,
                      "Template Cretaed Date": item.template_cretaed_date ?  item.template_cretaed_date.replace("T"," ").replace(".000Z","") : "",
                  });
              })
              : filedata.push({
                  "Doctor Name": "",
                  "Template Name": "",
                  "Email id": "",
                  "Downloaded By User": "",
                  "Template Cretaed Date": "",
              });
          return filedata;
            }else{
              fileData
              ? fileData.map((item, index) => {
                  return filedata.push({
                      "Template Name": item.template_name,
                      "Total Template Download Count": item.totalTemplateDownloadcount,
                  });
              })
              : filedata.push({
                  "Template Name": "",
                  "Total Template Download Count": "",
              });
          return filedata;
            }
  };
 const exportData = async(data,fileType) => {
      console.log('fun call')
      let filedata = await setDataWithHeader(data,fileType);
      let fileName = new Date().getTime() + "_" + `${fileType == "data" ? "Report" : "count"}`
      try {
          const fileType =
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(filedata);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
              bookType: "xlsx",
              type: "array",
          });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, fileName + fileExtension);
          // this.exportToClose();
      } catch (e) {
          swal("Error", { icon: "error" });
      }
  }

    const downloadReport = async(companyid)=>{
      const token = localStorage.getItem('user')?.token ?? config.accessToken;
      axios.post(config.baseURL + "/graphql",{
        query: `mutation{
          UseusertamplateDownloadTamplateReport(companyId:${companyid})
        }`
      },{headers:{access_token: token}})
      .then(async(res) => {
        console.log("iiiiiiiiii",res.data.data.UseusertamplateDownloadTamplateReport.data,"data")
        await exportData(res.data.data.UseusertamplateDownloadTamplateReport.data,"data")
      })
    }

    const downloadReportCount = (companyid) =>{
      const token = localStorage.getItem('user')?.token ?? config.accessToken;
      axios.post(config.baseURL + "/graphql",{
        query: `mutation{
          UseusertamplateDownloadTamplateReportCount(companyId:${companyid})
        }`
      },{headers:{access_token: token}})
      .then(async(res) => {
        console.log("iiiiiiiiii count",res.data.data.UseusertamplateDownloadTamplateReportCount.data)
        await exportData(res.data.data.UseusertamplateDownloadTamplateReportCount.data,"count")
      })
    }
  
    let columns = [
      // { field: "id", headerName: "ID", width: 200 },
      { field: "companyname", headerName: "Company Name", minWidth: 200, flex: 1},
      // { field: "companyemailid", headerName: "Company Email ID", width: 200 },
      // { field: "companymobilenumber", headerName: "Company Mobile", width: 200 },
      { field: "companyaddress", headerName: "Company Address", minWidth: 200, flex: 1 },
      { field: "companystate", headerName: "Company State", minWidth: 200, flex: 1 },
      { field: "companycity", headerName: "Company City", minWidth: 200, flex: 1 },
      { field: "action", headerName: "Actions", minWidth: 200, flex: 1 },
    ].filter((item) => {
      return item.field !== "action";
    });
  
    columns = [
      ...columns,
      {
        field: "action",
        headerName: "Action",
        minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <strong>
            <Edit
              onClick={() => {
                console.log(params.row);
                localStorage.setItem(
                  "EditRowData",
                  JSON.stringify({ ...params.row })
                );
                dispatch(getEditRowData());
                navigate("/admin/companies/edit");
              }}
              style={{ marginInline: "4px", cursor: "pointer",color:"green" }}
            />
            <TrashIcon
              onClick={() => {
                console.log(params.row);
                swal({
                  title: "Are you sure?",
                  text: "Are you sure you want to delete this company?",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then(willDelete => {
                  if(willDelete)
                    props.deleteHandler({ id: params.row.id });
                })
                // window.location.reload();
              }}
              style={{ marginInline: "4px", cursor: "pointer",color:"red" }}
            />
            <Receipt
             onClick={async() => {
              console.log(params.row.id);
              await downloadReport(params.row.id)
              await downloadReportCount(params.row.id)
              // window.location.reload();
            }}
            style={{ marginInline: "4px", cursor: "pointer"}}
            
            />
          </strong>
        ),
      },
    ];
    return (
      <Grid container xs={12} style={{ height: 600 }}>
        {/*{error && (<Grid xs={12}>Error! ${error.message}</Grid>)} */}
        <DataGridElement
          columns={columns}
          rows={rows}
          rowCount={totalCount || 0}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
          pageSize={pageSize}
          page={page}
          components={{ Toolbar: GridToolbar }}
          // paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          // sortingMode="server"
          // sortModel={sortModel}
          // onSortModelChange={handleSortModelChange}
          loading={loading}
        />
      </Grid>
    );
  };
  
  const ManageCompany = (props) => {
    const [fields, setFields] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const [formError, setFormError] = React.useState("");
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [formProcessing, setFormProcessing] = React.useState(false);
    const [loader, setLoader] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
  
    const createElement = (link, type) => {
      let element = document.createElement("a");
      element.setAttribute("href", link);
      element.setAttribute("download", type);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };
  
    const [loading, setLoading] = React.useState(false);

    const DELETE_COMPANY = gql`mutation saveCompany($obj:CompanyInput!){
      saveCompany(obj: $obj){
            id
        }
      }
    `;

    const [deleteCompanyMutation, { data3, error3 }]  = useMutation(DELETE_COMPANY);
    

    const deletePost = (field) => {
      
      let fieldsT = {};
      Object.assign(fieldsT, { id: field.id, active: 0 });
      deleteCompanyMutation({variables:{obj: {id: field.id,active:0}}}).then(() => {
        swal("","Company deleted successfully","success")
        .then(() => {
          window.location.reload();
        })
      })
      .catch((err) => {
        swal("","This company cannot be deleted as it contains associated users or templates.","warning");
      })
    };
    const handleValidation = (fieldname, e) => {
      let errorsT = errors;
      let formIsValid = true;
      let fieldValue = e ? (e.target ? e.target.value : e) : e;
      let updatedValue = fieldValue;
  
      setErrors({ ...errorsT });
      return { formIsValid, updatedValue };
    };
  
    const handleChange = (field, e) => {
      const { formIsValid, updatedValue } = handleValidation(field, e);
      let fieldsT = fields;
      fieldsT[field] = updatedValue;
      setFields({ ...fieldsT });
    };
  
    const toggleChecked = (field, e) => {
      let fieldsT = fields;
      if (fieldsT[field] === "") fieldsT[field] = true;
      else fieldsT[field] = !fieldsT[field];
      setFields({ ...fieldsT });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const { formIsValid, updatedValue } = handleValidation();
      if (formIsValid) {
        setDialogOpen(true);
      }
    };
    const deleteHandler = (item) => {
      deletePost({ id: item.id });
    };
    const { classes } = props;
    return (
      <form>
      
      <div className='main-table-wrapper'>
      
          <Grid container spacing={4}>
              <DataGrid_25 deleteHandler={deleteHandler} parentState={fields} />
          </Grid>
      </div>
      </form>
  );
  };
  export default ManageCompany;