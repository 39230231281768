import { createSlice } from '@reduxjs/toolkit'

// for saving user session state
export const userSlice = createSlice({
    name: 'user',
    initialState: {
      user: null
    },
    reducers: {
      getUser: state => {
        console.log("getuser",JSON.parse(localStorage.getItem("user")));  
        state.user = JSON.parse(localStorage.getItem("user"));
      },   
      saveUser: (state,data) => {
        console.log("auth guard saveuser data",data.payload);
        state.user = data.payload;
        localStorage.setItem("user",JSON.stringify(data.payload));
      },
      updateUser: (state,data) => {
        state.user = data;
        localStorage.setItem("user",JSON.stringify(data.payload));
      },
      logoutUser: state => {
        localStorage.removeItem("user");
        delete state.user;
      }
    }
  })
  

export const { getUser,updateUser,saveUser,logoutUser } = userSlice.actions;
  
export default userSlice.reducer;