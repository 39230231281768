import { createSlice } from '@reduxjs/toolkit'

// for saving user session state
export const editRowSlice = createSlice({
    name: 'editRow',
    initialState: {
      EditRowData: {}
    },
    reducers: {
      getEditRowData: state => {
        console.log("getEditRowData");  
        state.EditRowData = JSON.parse(localStorage.getItem("EditRowData"));
      },   
      setEditRowData: (state,data) => {
        state.EditRowData = data.payload;
        localStorage.setItem("EditRowData",JSON.stringify(data.payload));
      },
    }
  });
  

export const { getEditRowData,setEditRowData } = editRowSlice.actions;
  
export default editRowSlice.reducer;