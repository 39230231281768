// Super admin login page
import {
    Checkbox,
    Grid,
    TextField,
    FormControlLabel,
    Paper,
    Button,
    Typography
} from '@material-ui/core';


import axios from "axios";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import {config} from "../../config";
import { saveUser } from '../../redux/reducers/userSlice';
import { Link } from 'react-router-dom';

export default function AdminLogin(){
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(state => state.user.user);

    useEffect(() => {
        if(user){
            if(window.history && window.history.length > 0)
                navigate(-1); //navigate to previous route if available
            else
                navigate("/admin/companies");
            return;
        }
    });

    const handleAdminLogin = () => {
        
        const email = document.getElementById("admin_email").value;
        const password = document.getElementById("admin_password").value;
        if(email == "" || password == ""){
            swal("","Email and password are required","warning");
            return;
        }
        axios.post(`${config.baseURL}/login/?email=${email}&password=${password}`)
        .then(res => {
            if(res.data.statusCode > 0){
                swal("","Please enter Valid Email or Password.","error");
            }
            else{
                if(res.data.role == "USER"){
                    swal("","This user role cannot login from this panel","warning");
                    return;
                }
                // swal("","Login success","success")
                setTimeout(() => {
                    dispatch(saveUser({...res.data}));
                    navigate("/admin/companies");
                },200);      
            }
        })
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleAdminLogin();
        }
    }

    return (
        <div className='login-page-wrapper admin-login-page'>
            <div className="logo-wrapper">
                <Link to='/' className="logo-link">
                    <img src="../../../promedwise-logo.png" alt="Promedwise Logo" width="200px"/>
                </Link>
            </div>
            {!user && <Paper style={{textAlign:"center"}}>
                <Typography style={{fontSize:25}} align='center'>PROMEDWISE Admin Login</Typography> <br />
                <Grid
                container
                spacing={3}
                direction={'column'}
                justify={'center'}
                alignItems={'center'}
                >
                <Grid item lg={12}>
                    <TextField onKeyDown={handleKeyDown} id='admin_email' label="Email"></TextField>
                </Grid>
                <Grid item lg={12}>
                    <TextField onKeyDown={handleKeyDown} id='admin_password' label="Password" type={'password'}></TextField>
                </Grid>
        
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <Button onClick={handleAdminLogin} variant='contained' color="primary" fullWidth> Login </Button>
                </Grid>
                </Grid>
            </Paper>}
        </div>
    );
}
