import { ApolloClient,createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { config } from "./config";

const httpLink = createHttpLink({
    uri: config.baseURL + "/graphql?"
  });
  
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('user')?.token ?? config.accessToken;
  return {
      headers: {
      ...headers,
      access_token: token,
      }
  }
});
const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

export default client;