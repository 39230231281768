// Custom component for base image adjustment for multi-layer image templates

import React, { useRef, useEffect, useState } from 'react'
import Draggable from 'react-draggable';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';


const ImageAdjuster = ({frameLayerImageRef,baseLayerImage,coordinates,dimensionsImage,dimensionsFrame,
    contentFile,handleDrag,isEditMode = false
    , ...rest }) => {

    const canvasRef = useRef(null);
    const [renderedImage,setRenderedImage]  = useState()
  
    useEffect(() => {
        const baseLayerImageRef = "base_image_layer";
        // console.log("Image adjuster loaded with layers",baseLayerImageRef,frameLayerImageRef);
        console.log("Layer coordinates: ",coordinates)
        if(!frameLayerImageRef) throw new Error("Invalid frame layer for ImageAdjuster");
        
        const canvas = canvasRef.current;
        canvas.width = dimensionsFrame[0];
        canvas.height = dimensionsFrame[1];
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        // const frameLayer = document.getElementById(frameLayerImageRef);
        const frameLayer = frameLayerImageRef;
        const baseLayer = document.getElementById(baseLayerImageRef);

        context.drawImage(frameLayer, 0, 0,dimensionsFrame[0],dimensionsFrame[1]);
        context.globalCompositeOperation="destination-over";
        context.drawImage(baseLayer,coordinates[0],coordinates[1],dimensionsImage[0],dimensionsImage[1]);

        setRenderedImage(canvas.toDataURL(contentFile.type,1),contentFile.name,{
            type: contentFile.type,
            lastModified: contentFile.lastModified
        });

    }, [frameLayerImageRef,baseLayerImage,coordinates,dimensionsImage,dimensionsFrame,contentFile]);

     return (
        <>
            <canvas style={{display:"none"}} ref={canvasRef}/>
            {
                isEditMode ? (
                   <>
                    <Draggable 
                    defaultPosition={{x:0,y:0}} 
                    axis={"none"}
                    position={{x: 0, y: 0}}

                    onDrag={(event) => {
                        // event.preventDefault();
                        // event.stopImmediatePropagation();
                        handleDrag(event.clientX,event.clientY);
                    }}>
                        <img 
                            style={{cursor:"move",transform:"none"}} 
                            
                            id="adjusted_image" 
                            crossOrigin="anonymous" 
                            src={renderedImage} 
                            {...rest} 
                        />
                    </Draggable>
                   </>
                )
                : (
                    <TransformWrapper>
                        <TransformComponent>
                            <img 
                                id="adjusted_image" 
                                crossOrigin="anonymous" 
                                src={renderedImage} 
                                {...rest} 
                            />
                        </TransformComponent>
                    </TransformWrapper>
                )
            }
            
            
        </>
     )
}

export default ImageAdjuster;