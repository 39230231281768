// Main user-facing form which allows data to be entered as per template and media file (Video/Image/PDF) generated

import { Button, FormControl, Grid, Input, InputLabel, Link, MenuItem, Modal, Paper, Select, TextField, Tooltip, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { Route, useNavigate } from "react-router-dom";
import { config } from "../../config";

import ImageGallery from 'react-image-gallery';
import { saveUser } from "../../redux/reducers/userSlice";
import ImageAdjuster from "./ImageAdjuster";

import {ArrowBack, ArrowDownward, ArrowForward, ArrowUpward, Edit, OpenWithOutlined, ZoomIn, ZoomOut} from "@material-ui/icons"
function dataURLtoImageFile(dataurl, filename,data) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, data);
}


function renameFile(originalFile) {
    const originalName = originalFile.name.replace(" ","_");
    const extension = originalName.split(".")[1];
    const newName = new Date().getTime() + "." +extension;

    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

function getImageDimensions(imageFile){
    return new Promise((resolve,reject) => {
        const img = new Image();
        img.src = imageFile;
        img.onload = () => {
            resolve({height: img.height,width: img.width});
        };
    })
}

function loadRemoteImage(imageURL){
    return new Promise((resolve,reject) => {
        const image = new Image();
        image.setAttribute('crossOrigin', 'anonymous');

        image.onload = function() {
            resolve({height: image.height,width: image.width,elementRef: image});
        };
        image.onerror = function() {
            reject(new Error('Could not load remote image at ' + imageURL));
        };
        image.src = imageURL;
    })
}

export default function UserForm(){
    const user = useSelector(state => state.user.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [imagerotation,setImageRotation] = useState(0);
    const [loading,setLoading] = useState(false);
    const [modalOpen,setModalOpen] = useState(false);
    const [modalOpen2,setModalOpen2] = useState(false);
    const [activateEditMode,setActivateEditMode] = useState(false);
    const [imageCoordinates,setImageCoordnates] = useState([0,0]);
    const [frameDimensions,setFrameDimensions] = useState([0,0]);
    const [imageDimensions,setImageDimensions] = useState([0,0]);
    const [alreadyGenerated,setAlreadyGenerated] = useState(false);
    const [mediaURL,setMediaURL] = useState("");
    const [previewFile,setPreviewFile] = useState("");
    const [fileType,setFileType] = useState("");
    const [allData,setAllData] = useState({});
    const [templateChoices,setTemplateChoices] = useState([]);
    const [templateImages,setTemplateImages] = useState([]);
    const [contentFile, setContentFile] = useState(null);
    const [contentFileAdjusted, setContentFileAdjusted] = useState(null);
    const [templateImageFile, setTemplateImageFile] = useState(null);
    const [chosenTemplate, setChosenTemplate] = useState(null);
    const [chosenTemplateIndex, setChosenTemplateIndex] = useState(0);
    const [defaultLangId, setDefaultLangId] = useState(null);
    const [formIsValid, setFormIsValid] = useState(false);
    const [nameValidation,setNameValidation] = useState("");
    const [phonenumberValidation,setPhonenumberValidation] = useState("");

    var fieldsTT = {};

    const [fields,setFields] = useState({
        name: "",
        designation: "",
        qualification: "",
        phonenumber: "",
        address: "",
        companyId: user && user.campanyid ? user.campanyid : 0,
        languageId: 0,
        templateId: 0,
        profileimagename: ""
    });

    // Loads and saves the remote template file to be used for manipulations on the canvas
    const handleFileInput = (e) => {
        console.log(" i am callingggggggggggg")
        setAlreadyGenerated(false);
        setImageRotation(0);
        console.log("Original Image File: ",e.target.files[0]);
        if(e.target.files[0]){
            console.log("set file");
            setPreviewFile(URL.createObjectURL(e.target.files[0]));

            getImageDimensions(URL.createObjectURL(e.target.files[0])).then(imgdata_base => {
                console.log("imgdata_base",imgdata_base);
                console.log("templateChoices",templateChoices);
                const imgref2 = templateImages.length > 0 ? templateImages[chosenTemplateIndex].original : null;
                console.log("imgref2",imgref2);
                if(!imgref2) {
                    throw new Error("Invalid reference for frame layer");
                }
                if(imgref2){
                    loadRemoteImage(imgref2).then(imgdata_frame => {
                        console.log("imgdata_frame",imgdata_frame);
                        setTemplateImageFile(imgdata_frame.elementRef);
                        setImageCoordnates([0,0]);
                        // setImageDimensions([imgdata_base.width,imgdata_base.height]);
                        setImageDimensions([imgdata_frame.width,imgdata_frame.height]);
                        setFrameDimensions([imgdata_frame.width,imgdata_frame.height]);
                    })
                }
                
            });
        }

        //Check file type
        if(!e.target.files[0].type.includes("image")){
          e.preventDefault();
          setPreviewFile("");
          setContentFile(null);
          swal("","Only image files are allowed","warning");
          return false;
        } 

        if(e.target.files[0].type.includes("gif")){
            e.preventDefault();
            setPreviewFile("");
            setContentFile(null);
            swal("","GIF file is not supported","warning");
            return false;
        } 
        else if(e.target.files[0].type.includes("tiff")){
            e.preventDefault();
            setPreviewFile("");
            setContentFile(null);
            swal("","TIFF file is not supported","warning");
            return false;
        } 
        else if(e.target.files[0].type.includes("webp")){
            e.preventDefault();
            setPreviewFile("");
            setContentFile(null);
            swal("","WEBP file is not supported","warning");
            return false;
        } 
  
        // check max file size
        const maxFileSize = 10*1024*1024;
        console.log("file size:",e.target.files[0].size);
        if(e.target.files[0].size > maxFileSize){
          e.preventDefault();
          setPreviewFile("");
          setContentFile(null);
          swal("","Maximum file size is 10 MB","warning");
          return false;
        }
          
        const file = renameFile(e.target.files[0]);
        console.log("Updated image file:",file);
        setContentFile(file);
      }

    // upload content image if present
    const uploadContentImage = async (fileRequired = false) => {
        return new Promise((resolve,reject) => {
          if(!contentFile) {
            console.log("Skipping content file upload");
            let fieldsT = fields;
            fieldsT['profileimagename'] = null;
            setFields({ ...fieldsT });
            if(!fileRequired) resolve("Skipping content file upload");
            else reject("Doctor photo is required");
            return;
          }
          
  
          const data = new FormData();
          if(contentFileAdjusted)
            data.append('file',contentFileAdjusted);  //upload the adjusted image instead of original chosen
          else data.append('file',contentFile);
          axios.post(config.baseURL + "/api/Containers/images/upload",data,{
            headers:{
              "Content-type": "multipart/form-data"
            }
          })
          .then(res => {
            if(res.data.error) reject(res.data.error.message);
            console.log("upload result",res.data);
            const filename = res.data.result?.files?.file[0].name;
            let fieldsT = fields;
            fieldsT['profileimagename'] = filename;
            setFields({ ...fieldsT });
            resolve(filename);
          })
          .catch(err => reject(err));
        });
      }
    
    const setLoadingCustom = (value) => {
        setLoading(value);
        if(value === true){
            document.body.style.overflow = "hidden"
        }
        else{
            document.body.style.removeProperty('overflow');
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // if(
        //     fields.templateId == 0 || fields.companyId == 0 || fields.name == "" || fields.phonenumber == ""
        //     || fields.address == "" || fields.phonenumber == "" || fields.designation == "" || fields.qualification == "" 
        // )

        if(
            fields.templateId == 0 || fields.companyId == 0 || fields.name == "" 
        ){
            console.warn("fields",fields)
            swal("","Please fill all required fields","warning");
            return;
        }

        if(!formIsValid) return;

        setLoadingCustom(true);
        uploadContentImage(false).then((r) => {
            setMediaURL("");
            let url = "";
            let userName = fields.name.includes("Dr.") ||  fields.name.includes("Dr") || fields.name.includes("DR") ? fields.name : "Dr. " + fields.name 
            if(fields.profileimagename && fields.profileimagename != ""){
                url = config.baseURL + `/submit_data?name=${userName}&designation=${fields.designation}&qualification=${fields.qualification}&phone=${fields.phonenumber}&address=${fields.address}&templateId=${fields.templateId}&profileimagename=${fields.profileimagename}&userid=${user.id}`
            }
            else{
                url = config.baseURL + `/submit_data?name=${userName}&designation=${fields.designation}&qualification=${fields.qualification}&phone=${fields.phonenumber}&address=${fields.address}&templateId=${fields.templateId}&userid=${user.id}`
            }
            axios.post(url)
            .then(res => {
                setLoadingCustom(false);
                setAlreadyGenerated(true);
                swal("",res.data.message,"success").then(r => {
                    window.location.reload();
                })
                
                
                // if(res.data.outputFile){
                //     setLoadingCustom(false);
                //     setMediaURL(config.baseURL + res.data.outputFile);
                //     setFileType(res.data.fileType);
                //     setAlreadyGenerated(true);
                //     swal("",res.data.fileType + " generated successfully","success")
                //     .then(r => {
                //         // open media URL in new window
                //         window.open(config.baseURL + res.data.outputFile,'_blank').focus();
                //     })
                // }
                // else{
                //     if(res.data.pending){
                //         // media processing is pending on backend so use polling to repeatedly check
                //         const pollCheck = setInterval(() => {
                //             axios.post(config.baseURL + `/check_video_processed?filename=${res.data.filename}`)
                //             .then((r) => {
                //                 if(r.data.completed){
                //                     clearInterval(pollCheck);
                //                     setLoadingCustom(false);
                //                     setMediaURL(config.baseURL + r.data.outputFile);
                //                     setFileType(r.data.fileType);
                //                     setAlreadyGenerated(true);
                //                     swal("",res.data.fileType + " generated successfully","success")
                //                     .then(r2 => {
                //                         // open media URL in new window
                //                         window.open(config.baseURL + r.data.outputFile,'_blank').focus();
                //                     });
                //                 }
                //             });
                //         },10000);
                //     }
                //     else{
                //         console.error(res.data);
                //         setAlreadyGenerated(false);
                //         swal("","Media file failed to generate","error");
                //     }
                // }
            })
        })
        .catch(err => {
            setLoadingCustom(false);
            swal("",err,"warning");
        })
        
    }

    const handleChange = (fieldName,event) => {
        setAlreadyGenerated(false);
        const fieldsT = {...fields};

         // additional validation(s)
         if(fieldName == "name" && event.target.value != ""){
            if(!(/^[a-z ]+$/i.test(event.target.value))){
                setNameValidation("Please enter valid Name");
                setFormIsValid(false);
                return;
            }
            else setNameValidation("");
        }

        setFormIsValid(true);
        fieldsT[fieldName] = event.target.value;
        setFields(fieldsT);
        // console.log("Updated fields:",fieldsT);
        fieldsTT = fieldsT;

        if(fieldName == "languageId"){
            setDefaultLangId(event.target.value);
        }
    }
    const handleChangeTemplate = (templateIndex,templates_updated=null) => {
        setAlreadyGenerated(false);
        console.log("handleChangeTemplate index:",templateIndex,templates_updated,templateChoices);
        const fieldsT = {...fields};
        fieldsT["templateId"] = templates_updated ? templates_updated[templateIndex].id : templateChoices[templateIndex].id;
        setFields(fieldsT);
        setChosenTemplateIndex(templateIndex);
        // setChosenTemplate(templateChoices[templateIndex]);
        // console.log("Updated fields:",fieldsT);
        fieldsTT = fieldsT;
    }

    const updateTemplateChoices = (fieldsT3,allDataT3) => {
        console.log("fieldsT3",fieldsT3)
        setTimeout(() => {
            console.log("allData.allTemplates",allData.allTemplates)
            const templates = allDataT3 ? [...allDataT3.allTemplates] : [...allData.allTemplates];
            const fieldsT = fieldsT3 ? {...fieldsT3} : {...fieldsTT};
            // console.log("updated templates initial",templates);
            const templates_updated = templates.filter((t) => {
                // console.log(`updated checking : ${t.companyid} == ${fieldsT.companyId} && ${t.languagedid} == ${fieldsT.languageId}`);
                return (t.companyid == fieldsT.companyId && t.languagedid == fieldsT.languageId);
            });
            console.log("updated templates choices",templates_updated);
            setTemplateChoices(templates_updated);

            const template_images = templates_updated.map((t) => {
                const imageName =  t.filename.includes(".mp4") ? config.imageDownloadUrl + "/videoThumbnail.png" : t.filename.includes(".pdf") ? config.imageDownloadUrl +"/PDF_file_icon.png" : config.baseURL + "/api/Containers/images/download/" + t.filename;
                return {
                    id: t.id,
                    original: imageName,
                    thumbnail: imageName,
                    originalTitle: t.templatename,
                    istransparentimage: t.istransparentimage,
                    originalAlt: config.baseURL + "/api/Containers/images/download/" + t.filename
                };
            });
            console.log("template_images",template_images);
            setTemplateImages(template_images);
            handleChangeTemplate(0,templates_updated); //pre-select the first template
            console.log("(templates_updated[0](templates_updated[0]",templates_updated[0])
            setChosenTemplate(templates_updated[0]);
        },400);
    }

    const rotateImage = () => {
        if(!previewFile) return;
        let angle = parseInt(imagerotation + 90);
        if(angle > 360) angle = angle - 360;
        console.log("rotate image to:" + angle);
        setImageRotation(angle);
    }

    

    const saveRotatedImage = () => {
        setAlreadyGenerated(false);
        if(!previewFile) return;

        const img = new Image();
        img.src = previewFile;
        img.onload = () => {
            const canvas = document.getElementById("imageCanvas");
           
            const maxDim = Math.max(img.height, img.width);
            if ([90, 270].indexOf(imagerotation) > -1) {
                canvas.width = img.height;
                canvas.height = img.width;
            } else {
                canvas.width = img.width;
                canvas.height = img.height;
            }

            const ctx = canvas.getContext("2d");
            ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
            ctx.rotate(imagerotation * (Math.PI / 180));
            ctx.drawImage(img, -maxDim / 2, -maxDim / 2);

            const rotatedImageFile = dataURLtoImageFile(canvas.toDataURL(contentFile.type),contentFile.name,{
                type: contentFile.type,
                lastModified: contentFile.lastModified
            });
            console.log("Saved rotated image:",rotatedImageFile);
            setContentFile(rotatedImageFile);
        };
    }

    const saveAdjustedImage = () => {
        const imgElement = document.getElementById('adjusted_image')

        fetch(imgElement.src)
        .then(res => res.blob())
        .then(blob => {
            const file = new File([blob], contentFile.name, blob)
            console.log(file);
            setContentFileAdjusted(file);
        })
    }
    
    useEffect(() => {
        if(!user){
            navigate("/login");
            return;
        }

        if(user.role == "SUPERADMIN"){
            navigate("/admin/");
            return;
        }
        console.log("tokennnnn",localStorage.getItem('user')?.token);
        axios({
            url: config.baseURL + "/graphql",
            method: 'post',
            data: {
             query: `query{
                Userdata(id:${user.id}){
                  email
                  campanyid
                }
              }
              `,
            },
            headers:{
                'Content-Type': 'application/json',
                'access_token': 'M8ATS5UFxuxDOVnMEhkBFOTBIulzAF7IUDF7XzRQ2U1fC3k9xvHD8Fu0hMRJsJ7L'
            }
          })
          .then((result) => {
              const updated_userdata = {...user,...result.data.data.Userdata};
              console.log("Userdata updated",updated_userdata);
              axios.get(config.baseURL + "/api/Companies/isCompanyActive?companyId=" + user.campanyid + "&userId=" + user.id)
              .then(resp => {
            
                if(!resp.data.response.msg || resp.data.response.msg === false){
                    //   logout the user because company is deleted
                    localStorage.removeItem("user");
                    window.location.reload();
                    return;
                }
                dispatch(saveUser(updated_userdata));
                axios({
                    url: config.baseURL + "/graphql",
                    method: 'post',
                    data: {
                    //   query: `query{
                    //     allTemplates{
                    //       Templates{
                    //         id
                    //         companyid
                    //         languagedid
                    //       }
                    //     }
                    //     allCompanies{
                    //         Companies{
                    //             id
                    //             companyname
                    //         }
                    //     }
                    //     allLanguages{
                    //         Languages{
                    //             id
                    //             languagename
                    //         }
                    //     }
                    //   }`,
                    query: `mutation{
                        TemplateGetAllTemplate
                        LanguageGetAllLanguageForUser(companyId: ${result.data.data.Userdata.campanyid})
                    }`,
                    },
                    headers:{
                        'Content-Type': 'application/json',
                        // Authorization: `Bearer ${accessToken}`
                        // 'access_token': 'M8ATS5UFxuxDOVnMEhkBFOTBIulzAF7IUDF7XzRQ2U1fC3k9xvHD8Fu0hMRJsJ7L'
                    }
                })
                .then((result) => {
                    console.log("TemplateGetAllTemplate",result.data.data.TemplateGetAllTemplate);
                    let languages_filtered = {allLanguages: [...result.data.data.LanguageGetAllLanguageForUser.data]};
                    console.log("languages_filtered",languages_filtered);
                    setAllData({...result.data.data.TemplateGetAllTemplate.data,...languages_filtered});
                    // console.log("alldata",{...result.data.data.TemplateGetAllTemplate.data,...languages_filtered})
                    // console.log("filtered",languages_filtered.allLanguages[0].languagedid)
                    setDefaultLangId(languages_filtered.allLanguages[0].languagedid); //pre-select first language
                    // setTemplateChoices([]);
                    // updateTemplateChoices();
                    const fieldsT = {...fields};
                    fieldsT["companyId"] = updated_userdata.campanyid;
                    fieldsT["languageId"] = languages_filtered.allLanguages[0].languagedid;
                    setFields(fieldsT);
                    console.log("updated template choices-input",fieldsT);
                    updateTemplateChoices(fieldsT,{...result.data.data.TemplateGetAllTemplate.data,...languages_filtered});
                })
                .catch(err => {
                    console.error("GraphQL error: ", err);
                });
              })
              

          })
          .catch(err => {
              console.error("GraphQL error: ", err);
          });
        
        
    },[]);

    const adjustImageDirection = (direction = "up") => {
        // console.log("adjustImageDirection: ",direction);
        const coordinates = [...imageCoordinates];
        const amt = 10;
        if(direction == "up"){
            coordinates[1] -= amt;
        }
        else if(direction == "down"){
            coordinates[1] += amt;
        }
        else if(direction == "left"){
            coordinates[0] -= amt;
        }
        else if(direction == "right"){
            coordinates[0] += amt;
        }

        setImageCoordnates(coordinates);
    } 
    
    // handles zooming for base layer image
    const adjustImageZoom = (direction = "increase") => {
        const dimensions = [...imageDimensions];
        const amt = 100;
        if(direction == "increase"){
            dimensions[0] += amt;
            dimensions[1] += amt;   
        }
        else{
            dimensions[0] -= amt;
            dimensions[1] -= amt;   
        }

        setImageDimensions(dimensions);
    } 

    const handleDrag = (x,y) => {
        // console.log("Coordinates: ",event.clientX,event.clientY);
        setImageCoordnates([x,y]);
    }
  
    

    return (
        <>
        <Modal
          open={modalOpen}
          onClose={() => {setModalOpen(false)}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-wrapper upload-image-modal"
        >
          <Paper className="modal-body">
            <div className="modal-heading-wrapper">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                <label htmlFor="imagefile" className="browse-btn">Browse Photo</label>
                <input 
                    id='imagefile'
                    label='Browse Photo' 
                    type='file'
                    accept="image/*"
                    style={{visibility: "hidden"}}
                    onChange={(e) => {handleFileInput(e)}}
                />
                </Typography>
            </div>
            {console.log("previewFilepreviewFilepreviewFile",previewFile)}
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div  className="image-preview-wrapper">
                    <div className="img-wrap" style={{transform: `rotate(${imagerotation}deg)`}}>
                        <img src={previewFile} width={200} height={200} />
                    </div>
                    <p></p>
                    <Button onClick={rotateImage}>Rotate</Button>
                </div>
                <Button 
                    onClick={() => {saveRotatedImage(); setModalOpen(false)}} 
                    variant="outlined" 
                    color="primary" 
                    className="blue-btn"
                >
                    Use Photo
                </Button>
            </Typography>
          </Paper>
        </Modal>

        <Modal
          open={modalOpen2}
          onClose={() => {setModalOpen2(false)}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-wrapper upload-image-modal"
          
        >
          <Paper className="modal-body" style={{width:"100%"}}>
            {/* <div className="modal-heading-wrapper">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Adjust Photo in Frame
                </Typography>
            </div> */}
            {    
                previewFile && 
                <Typography style={{overflowY: "scroll"}} id="modal-modal-description" sx={{ mt: 2 }}>
                    <div className="canvas-container" style={{
                        overflow: "scroll",
                        maxWidth: "90%", height: 500
                    }}>
                        {
                    
                            <ImageAdjuster
                            width={400}
                            height={400}
                            frameLayerImageRef={templateImageFile} 
                            coordinates={imageCoordinates}
                            dimensionsImage={imageDimensions}
                            dimensionsFrame={frameDimensions}
                            contentFile={contentFile}
                            // handleDrag={handleDrag}
                            isEditMode={activateEditMode}
                            />
                        
                        }
                    </div>  
                    {console.log("activateEditModeactivateEditMode",activateEditMode)}
                    {
                        activateEditMode && 
                        <>
                            <div className="adjustcontrols">
                                <button className="adjustctrl-btn" onClick={() => adjustImageDirection("up")}><ArrowUpward fontSize="1rem" /></button>  
                            </div>
                            
                            <div className="adjustcontrols">
                                <button className="adjustctrl-btn" onClick={() => adjustImageDirection("left")}><ArrowBack fontSize="1rem" /></button>
                                <button className="adjustctrl-btn" onClick={() => adjustImageDirection("right")}><ArrowForward fontSize="1rem" /></button>
                            </div>
                        
                            <div className="adjustcontrols">
                                <button className="adjustctrl-btn" onClick={() => adjustImageDirection("down")}><ArrowDownward fontSize="1rem" /></button>
                            </div>
                        </>
                    }

                    {/* <div className="adjustcontrols">
                        <button className="adjustctrl-btn" onClick={() => adjustImageZoom("increase")}><ZoomIn /></button>
                        <button className="adjustctrl-btn" onClick={() => adjustImageZoom("decrease")}><ZoomOut /></button>
                    </div> */}

                    <div className="adjustcontrols">
                        <Tooltip title="Adjust Image Position" placement="top">
                            <button style={{background: activateEditMode && "#b0b1b4" }} className="adjustctrl-btn" onClick={() => setActivateEditMode(!activateEditMode)}>
                                <OpenWithOutlined />
                            </button>
                        </Tooltip>
                    </div>
                    
                    <Button 
                        onClick={() => {saveAdjustedImage(); setModalOpen2(false)}} 
                        variant="outlined" 
                        color="primary" 
                        className="blue-btn"
                        style={{marginTop:10}}
                    >
                        Confirm
                    </Button>
                </Typography>
            }
            {
                !previewFile && (
                    <Typography>
                        <b>Please select a file first</b>
                    </Typography>
                )
            }
          </Paper>
        </Modal>
        
            <div className="header-wrapper">
                <div className="logo-wrapper">
                    <Link to='/' className="logo-link">
                        <img src="../../../promedwise-logo.png" alt="Promedwise Logo" width="100px"/>
                    </Link>
                </div>
                <br />
                
            </div>
            <canvas style={{display:"none"}} id="imageCanvas" />
            <div className="main-form-wrapper">
            {/* <div style={{textAlign:"center",marginTop:"1rem"}}>
                    {
                        user && <a href="#" onClick={(e) => {
                            e.preventDefault();
                            navigate("/submission-history")
                        }} className="logout-btn"><b><u>Template download from here</u></b></a>
                    }
                </div> */}
                <div style={{textAlign:"center"}} className="form-wrapper">
                    <div class="overlay" style={{display: loading ? "block" : "none"}}>
                        <div class="overlay__inner">
                            <div class="overlay__content"><span class="spinner"></span></div>
                        </div>
                    </div>
                    {/* <div className="heading-text">
                        <h2>Submit Data </h2>
                    </div>
                     */}
                    <div className="form-fields-wrapper">
                        <Grid style={{marginTop:20}} container spacing={4}>
                            <Grid style={{display:"none"}} item xs={6}>
                                <FormControl>
                                    <InputLabel id="demo-dropdown-label">Company (*)</InputLabel>
                                    <Select
                                        labelId="demo-dropdown"
                                        id="companydropdown"
                                        style={{ minWidth: 230,textAlign:"left" }}
                                        name="companyid"
                                        value={user && user.campanyid ? user.campanyid : 0}
                                        // onChange={(e) => {handleChange("companyId",e);updateTemplateChoices();}}
                                        readOnly={true}
                                    >
                                        {
                                            allData.allCompanies && allData.allCompanies.map((obj) => {
                                                return (
                                                    <MenuItem key={obj.id} value={obj.id}>
                                                    {obj.companyname}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} className="content-wrapper">
                                <div className="profile-content-wrap">
                                    <Grid item xs={12} className="dropdown-label">
                                        <FormControl>
                                            <InputLabel id="demo-dropdown-label">
                                                Select Language 
                                                <span aria-hidden="true" class="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span>
                                            </InputLabel>
                                            <Select
                                                labelId="demo-dropdown"
                                                id="languagedropdown"
                                                displayEmpty={false}
                                                value={defaultLangId ? defaultLangId : ""}
                                                style={{ minWidth: 230,textAlign:"left" }}
                                                name="languageId"
                                                onChange={(e) => {handleChange("languageId",e);updateTemplateChoices();}}
                                                required
                                            >
                                                {
                                                    allData.allLanguages && allData.allLanguages
                                                    .sort((a, b) => a.languagename.localeCompare(b.languagename))
                                                    .map((obj) => {
                                                        return (
                                                            <MenuItem key={obj.id} value={obj.id}>
                                                            {obj.languagename}
                                                            </MenuItem>
                                                        );
                                                    })
                                                }
                                                {
                                                    allData.allLanguages && allData.allLanguages.length == 0 && (
                                                        <MenuItem key={0} value={""}>
                                                            No languages found
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    
                                    <img style={{display: "none"}} id="base_image_layer" src={previewFile} width={200} height={200} />
                                    <div className="upload-image-wrapper">
                                        <button onClick={() => {setModalOpen(true)}} className="upload-btn">Use Photo</button>
                                    </div>
                                    {
                                        chosenTemplate && chosenTemplate.istransparentimage == 1  && (
                                            <div className="upload-image-wrapper">
                                                <button onClick={() => {setModalOpen2(true)}} className="upload-btn">Adjust Photo</button>
                                            </div>
                                        )
                                    }
                                    

                                    <Grid item xs={12} className="input-fields-wrapper">
                                        <Grid item xs={12} >            
                                            <input variant="outlined"
                                            id='name'
                                            label='Name' 
                                            type='text'
                                            onChange={(e) => {handleChange("name",e)}}
                                            required
                                            placeholder="Name (*)"
                                            className="input-field"
                                            /> <div style={{color: "red"}} className="form-error">{nameValidation}</div>
                                        </Grid>

                                        <Grid item xs={12} >            
                                            <input variant="outlined"
                                            id='designation'
                                            label='Designation' 
                                            type='text'
                                            required
                                            className="input-field"
                                            placeholder="Designation"
                                            onChange={(e) => {handleChange("designation",e)}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} >            
                                            <input variant="outlined"
                                            id='qualification'
                                            label='Qualification' 
                                            type='text'
                                            required
                                            className="input-field"
                                            placeholder="Qualification"
                                            onChange={(e) => {handleChange("qualification",e)}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} >            
                                            <input variant="outlined"
                                            id='phone'
                                            label='Phone Number' 
                                            type='text'
                                            className="input-field"
                                            placeholder="Mobile Number"
                                            required
                                            onChange={(e) => {handleChange("phonenumber",e)}}
                                            /> <div style={{color: "red"}} className="form-error">{phonenumberValidation}</div>
                                        </Grid>

                                        <Grid item xs={12} >            
                                            <input variant="outlined"
                                            id='address'
                                            label='Address' 
                                            type='text'
                                            required
                                            className="input-field"
                                            placeholder="Address"
                                            onChange={(e) => {handleChange("address",e)}}
                                            />
                                        </Grid>                
                                    </Grid>

                                    <Grid item xs={12} >
                                        <Button color="primary" variant="contained"  disabled={(loading) || templateImages.length == 0 || alreadyGenerated}  onClick={(e)=>{handleSubmit(e)}}
                                        id='submitbtn'>
                                            Submit
                                        </Button>
                                    </Grid> 

                                    {/* {
                                        previewFile !="" && (
                                            <Grid item xs={12} className="image-preview-wrapper">
                                                <b>Image Preview:</b>
                                                <img src={previewFile} width={200} height={200} />
                                            </Grid>
                                        )
                                    } */}
                                </div>
                                <div className="slider-wrap">
                                    <Grid item xs={12}>
                                        <div className="image-gallery-wrapper">
                                            {
                                                templateImages.length > 0 &&
                                                <ImageGallery 

                                                onSlide={(currentIndex) => {
                                                    handleChangeTemplate(currentIndex);
                                                }}
                                                renderItem={
                                                    (item) => (
                                                        <div style={{textAlign:"center"}}>
                                                            <h3><span>Template: </span> <a target="_blank" href={item.originalAlt}>{item.originalTitle}</a></h3>  
                                                            <a target="_blank" href={item.originalAlt}>
                                                                <img id={"templateimage_" + item.id} class="image-gallery-image" src={item.original} alt="" srcset="" height="" width="" sizes="" title="Arweave PDF" />
                                                            </a>
                                                        </div>
                                                    )
                                                }
                                                showFullscreenButton={false}
                                                showPlayButton={false}
                                                items={templateImages} 
                                            />}
                                            {
                                                templateImages.length == 0 && 
                                                <b>No templates found. Please choose a different language.</b>
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Button color="primary" variant="contained" onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/submission-history")
                                        }}
                                            id='submitbtn'>
                                            Download Template
                                        </Button>
                                    </Grid>
                                </div>
                            </Grid>
                            
                        </Grid>
                    </div>
                    
                    {
                        mediaURL != "" && (
                            <div className='media-link'>
                                <b>Generated {fileType} URL: </b> <br />
                                <a href={mediaURL} target="_blank">{mediaURL}</a>
                            </div>
                        )
                    }
                </div>

                <div className='footer-link'>
                    <Link to="https://www.promedica.com">www.promedwise.com</Link>
                </div>
                {/* <div style={{textAlign:"center",marginTop:"1rem"}}>
                    {
                        user && <a href="#" onClick={(e) => {
                            e.preventDefault();
                            navigate("/submission-history")
                        }} className="logout-btn">Submission History</a>
                    }
                </div> */}
                 <div style={{textAlign:"center",marginTop:"1rem"}}>
                    {
                        user && <a href="#" onClick={() => {
                            localStorage.removeItem("user");
                            window.location.reload();
                        }} className="logout-btn">Logout</a>
                    }
                </div>
            </div>
        </>
    );
}