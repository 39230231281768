import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
  } from "react";
  import Grid from "@material-ui/core/Grid";
  import PropTypes from "prop-types";
  import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
  import { gql, useMutation, useQuery } from "@apollo/client";
  import TextField from "@material-ui/core/TextField";
  import Button from "@material-ui/core/Button";
  import Dialog from "@material-ui/core/Dialog";
  import DialogContent from "@material-ui/core/DialogContent";
  import DialogContentText from "@material-ui/core/DialogContentText";
  import DialogActions from "@material-ui/core/DialogActions";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { utils } from "../../utils";
  
  const styles = makeStyles((theme) => ({}));
  //Class Designer :
  //Components
  
  const AddCompanyInner = (props) => {
    const EditRowData = useSelector((state) => state.EditRowData.EditRowData);
    console.log("EditRowData :",EditRowData);

    const [fields, setFields] = React.useState({...EditRowData} ?? {});
    const [errors, setErrors] = React.useState({});
    const [formError, setFormError] = React.useState("");
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [formProcessing, setFormProcessing] = React.useState(false);
    const [loader, setLoader] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [companyEmailChanged, setCompanyEmailChanged] = useState(false);
  
    const createElement = (link, type) => {
      let element = document.createElement("a");
      element.setAttribute("href", link);
      element.setAttribute("download", type);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };
   
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const ADD_COMPANY = gql`mutation{
      CompanyCreate(data:{
        companyaddress:"${fields['companyaddress']}",
        companyname:"${fields['companyname']}",
        companycity:"${fields['companycity']}",
        companystate:"${fields['companystate']}",
        companymobilenumber:"${fields['companymobilenumber']}",
        companyemailid:"${fields['companyemailid']}",
      }){
        id
      }
    }`;

    const UPDATE_COMPANY = gql`mutation{
      CompanyUpdateAll(where:{id:${fields['id']}},data:{
        companyaddress:"${fields['companyaddress']}",
        companyname:"${fields['companyname']}",
        companycity:"${fields['companycity']}",
        companystate:"${fields['companystate']}",
        companymobilenumber:"${fields['companymobilenumber']}",
        companyemailid:"${fields['companyemailid']}",
      })
    }`;
    const UPDATE_COMPANY2 = gql`mutation{
      CompanyUpdateAll(where:{id:${fields['id']}},data:{
        companyaddress:"${fields['companyaddress']}",
        companyname:"${fields['companyname']}",
        companycity:"${fields['companycity']}",
        companystate:"${fields['companystate']}",
        companymobilenumber:"${fields['companymobilenumber']}",
      })
    }`;

    const [addCompanyMutation, { data2, error2 }]  = useMutation(ADD_COMPANY);
    const [updateCompanyMutation, { data3, error3 }]  = useMutation(UPDATE_COMPANY);
    const [updateCompanyMutation2, { data4, error4 }]  = useMutation(UPDATE_COMPANY2);
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const downloadFile = (path) => {
      setLoader(true);
      const filePath = path;
      fetch(filePath)
        .then((res) => res.blob())
        .then((res) => {
          setLoader(false);
          let url = window.URL.createObjectURL(res);
          let type = res.type;
          createElement(url, type);
        })
        .catch((err) => {
          setLoader(false);
          swal("Something went wrong", {
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          console.log(err);
        });
    };
  
    // const addMoreDataHandler = (item) => {
    //   router.push({ pathname: item });
    // };
    const handleDialogClose = () => {
      setDialogOpen(false);
      if (false) {
        
      }
      setFormProcessing(false);
    };
    function submitCompleted(data) {
      setFormProcessing(true);
      setDialogOpen(true);
    }
  
    const [loading, setLoading] = React.useState(false);
  
    // const deletePost = (field) => {
    //   let fieldsT = {};
    //   Object.assign(fieldsT, { id: field.id, active: 0 });
    //   saveMutation({
    //     variables: {
    //       input: fieldsT,
    //     },
    //   });
    // };
    const handleValidation = (fieldname, e) => {
      let errorsT = errors;
      let formIsValid = true;
      let fieldValue = e ? (e.target ? e.target.value : e) : e;
      let updatedValue = fieldValue;
      //companyname validations
      if (fieldname === undefined || fieldname === "companyname") {
        if (fieldname === undefined) {
          fieldValue = fields["companyname"] ? fields["companyname"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["companyname"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
          formIsValid = false;
          errorsT["companyname"] = "Company Name is required";
        }
      }
  
      //companyemailid validations
      if (fieldname === undefined || fieldname === "companyemailid") {
        if (fieldname === undefined) {
          fieldValue = fields["companyemailid"] ? fields["companyemailid"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["companyemailid"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
          formIsValid = false;
          errorsT["companyemailid"] = "Company Email is required";
        }
        else{
          if(!utils.validateEmail(fieldValue)){
            formIsValid = false;
            errorsT["companyemailid"] = "Please enter valid email";
          }
        }
      }
  
      //companymobilenumber validations
      if (fieldname === undefined || fieldname === "companymobilenumber") {
        if (fieldname === undefined) {
          fieldValue = fields["companymobilenumber"] ? fields["companymobilenumber"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["companymobilenumber"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        }

        if(fieldValue && (fieldValue.length != 10 || !(/^\d+$/.test(fieldValue)))){
          formIsValid = false;
          errorsT["companymobilenumber"] = "Please enter valid phone number";
        }
      }
  
      //companyaddress validations
      if (fieldname === undefined || fieldname === "companyaddress") {
        if (fieldname === undefined) {
          fieldValue = fields["companyaddress"] ? fields["companyaddress"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["companyaddress"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        }
      }
  
      //companystate validations
      if (fieldname === undefined || fieldname === "companystate") {
        if (fieldname === undefined) {
          fieldValue = fields["companystate"] ? fields["companystate"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["companystate"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        }
      }
  
      //companycity validations
      if (fieldname === undefined || fieldname === "companycity") {
        if (fieldname === undefined) {
          fieldValue = fields["companycity"] ? fields["companycity"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["companycity"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        }
      }
  
      setErrors({ ...errorsT });
      return { formIsValid, updatedValue };
    };
  
    const handleChange = (field, e) => {    
      const { formIsValid, updatedValue } = handleValidation(field, e);

      if(EditRowData && field == "companyemailid") {
        if(updatedValue != EditRowData.companyemailid ){
          setCompanyEmailChanged(true);
          setDisabledButton(false);
        }
        else{
          setCompanyEmailChanged(false);
          setDisabledButton(true);
        }
      }
      else{
        setDisabledButton(false);
      }

      let fieldsT = fields;
      fieldsT[field] = updatedValue;
      setFields({ ...fieldsT });
    };
  
    const toggleChecked = (field, e) => {
      let fieldsT = fields;
      if (fieldsT[field] === "") fieldsT[field] = true;
      else fieldsT[field] = !fieldsT[field];
      setFields({ ...fieldsT });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const { formIsValid, updatedValue } = handleValidation();
      if (formIsValid) {
        if(localStorage.getItem("EditRowData")){
          if(companyEmailChanged){
            updateCompanyMutation().then(() => {
              swal("","Company updated successfully","success");
              setTimeout(() => {
                window.location.href = "/#/admin/companies";
              }, 1200);
            })
            .catch(err => {
              console.error(err);
              swal("","Email already exists","warning");
            })
          }
          else{
            // donot update the email in this case
            updateCompanyMutation2().then(() => {
              swal("","Company updated successfully","success");
              setTimeout(() => {
                window.location.href = "/#/admin/companies";
              }, 1200);
            })
            .catch(err => {
              console.error(err);
              swal("","Email already exists","warning");
            })
          }
          
        }
        else{
          addCompanyMutation().then(() => {
            swal("","Company added successfully","success");
            setTimeout(() => {
              window.location.href = "/#/admin/companies";
            }, 1200);
          }).catch(err => {
            // swal("","Error:" + err.message,"error");
            swal("","Email already exists","warning");
          });
        }
        // setDialogOpen(true);
      }
    };
    // const deleteHandler = (item) => {
    //   deletePost({ id: item.id });
    // };
    // const { classes } = props;
    return (
      <form>
      
      <div className="form-wrapper">
      
          <Grid container spacing={4}>
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='companyname'
                        label='Company Name' 
                        type='text'
                        value={fields.companyname || ''}
                        onChange={(e) => {handleChange("companyname",e)}}
                        onBlur={(e) => {handleChange("companyname",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        required
                        /><div style={{color: "red"}} className="form-error">{errors["companyname"]}</div>
                    </Grid>
  
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='companyemailid'
                        label='Company Email' 
                        type='email'
                        value={fields.companyemailid || ''}
                        onChange={(e) => {handleChange("companyemailid",e)}}
                        onBlur={(e) => {handleChange("companyemailid",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        required
                        /><div style={{color: "red"}} className="form-error">{errors["companyemailid"]}</div>
                    </Grid>
  
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='companymobilenumber'
                        label='Company Phone Number' 
                        type='text'
                        value={fields.companymobilenumber || ''}
                        onChange={(e) => {handleChange("companymobilenumber",e)}}
                        onBlur={(e) => {handleChange("companymobilenumber",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        /><div style={{color: "red"}} className="form-error">{errors["companymobilenumber"]}</div>
                    </Grid>
  
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='companyaddress'
                        label='Company Address' 
                        type='text'
                        value={fields.companyaddress || ''}
                        onChange={(e) => {handleChange("companyaddress",e)}}
                        onBlur={(e) => {handleChange("companyaddress",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        />
                    </Grid>
  
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='companystate'
                        label='Company State' 
                        type='text'
                        value={fields.companystate || ''}
                        onChange={(e) => {handleChange("companystate",e)}}
                        onBlur={(e) => {handleChange("companystate",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        />
                    </Grid>
  
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='companycity'
                        label='Company City' 
                        type='text'
                        value={fields.companycity || ''}
                        onChange={(e) => {handleChange("companycity",e)}}
                        onBlur={(e) => {handleChange("companycity",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        />
                    </Grid>
  
                  <Grid item xs={12} >
                  <div style={{ color: "red" }}>{formError}</div>
                      <Button color="primary" variant="contained"  disabled={(loading || formProcessing || disabledButton)}  onClick={(e)=>{handleSubmit(e)}}
                      id='button_A828FCAF7C3F4E1BAE41A2D72318EF6F' >
                        
          {(loading || formProcessing)  && (
              <span style={{color:"white"}}>
              <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
              /> Please Wait...
              </span>
          )}
          {!(loading || formProcessing)  && <span>{typeof(localStorage) !== 'undefined' && localStorage.getItem('EditRowData')?"Update":"Submit"}</span>}
          </Button>
          &nbsp;&nbsp;
          <Button color="primary" style={{backgroundColor: "#d9534f",borderColor: "#d43f3a"}} variant="contained"  onClick={(e)=>{
            window.location.href = "/#/admin/companies";
          }}>Cancel</Button>
          <Dialog
          open={dialogOpen}
          onClose={handleDialogClose} disableEscapeKeyDown={true}
          aria-describedby="alert-dialog-description">
          <DialogContent>
          <div style={{width:"300px"}}>
          <DialogContentText id="alert-dialog-description">
             {"Success!!"}
          </DialogContentText>
          </div>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
              Ok
          </Button>
          </DialogActions>
      </Dialog>
                  </Grid>
          </Grid>
      </div>
      </form>
  );
  };
  AddCompanyInner.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  const CompWithStyleInner = withStyles(styles, { withTheme: true })(
    AddCompanyInner
  );
  function AddCompany(props) {
    return <CompWithStyleInner props={props}/>;
  }
  export default AddCompany;