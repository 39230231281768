import * as React from 'react';

import { AppBar,Box,FormGroup,Switch,Toolbar,IconButton,MenuItem,Menu,FormControlLabel,Typography, Container } from '@material-ui/core';
import { GridMenuIcon } from '@material-ui/data-grid';
import {Person} from "@material-ui/icons";

import { useDispatch,useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function DashBoardHeader({pageTitle="Dashboard"}) {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const user = useSelector(state => state.user.user);
  
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.removeItem("user");
    window.location.reload();
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      {
        user && 
            <AppBar position="static">
            <Toolbar style={{justifyContent:"space-between"}}>
              <Link to="/admin">
                <Typography variant="h5" className='headerlink' component="div" sx={{ flexGrow: 1 }}>
                  Super Admin
                </Typography>
              </Link>

              <Typography className='headerlink' variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <Link to="/admin/companies">Companies</Link>
              </Typography>

              <Typography className='headerlink' variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <Link to="/admin/users">Users</Link>
              </Typography>

              <Typography className='headerlink' variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <Link to="/admin/templates">Templates</Link>
              </Typography>


              {auth && (
                <div>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    {/* <AccountCircle /> */}
                    <Person />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                    <MenuItem onClick={logout}>Logout</MenuItem>
                  </Menu>
                </div>
              )}
            </Toolbar>
          </AppBar>
      }
      
      
      {/* <div style={{textAlign:"left"}} className="page-title">
        <h2>{pageTitle}</h2>
      </div> */}

    </Box>
  );
}