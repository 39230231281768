import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
  } from "react";
  import Grid from "@material-ui/core/Grid";
  import PropTypes from "prop-types";
  import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
  import { gql, useMutation, useQuery } from "@apollo/client";
  
  import TextField from "@material-ui/core/TextField";
  import Button from "@material-ui/core/Button";
  import Dialog from "@material-ui/core/Dialog";
  import DialogContent from "@material-ui/core/DialogContent";
  import DialogContentText from "@material-ui/core/DialogContentText";
  import DialogActions from "@material-ui/core/DialogActions";

  import Select from "@material-ui/core/Select";
  import Input from "@material-ui/core/Input";
  import InputLabel from "@material-ui/core/InputLabel";
  import MenuItem from "@material-ui/core/MenuItem";
  import FormControl from "@material-ui/core/FormControl";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { utils } from "../../utils";

  
  const styles = makeStyles((theme) => ({}));
  //Class Designer :
  //Components
  
  const AddUserInner = (props) => {
    const EditRowData = useSelector((state) => state.EditRowData.EditRowData);
    // console.log("EditRowData :",EditRowData);
    let tempEditRowData = {...EditRowData};
    if(tempEditRowData.userdatacampanyidmaprel){
      if(tempEditRowData.userdatacampanyidmaprel.Companies.length == 0){
        tempEditRowData.campanyid = null;
      }
    }
    // console.log("tempEditRowData",tempEditRowData)
    
    const [fields, setFields] = React.useState({...tempEditRowData} ?? {});
    const [errors, setErrors] = React.useState({});
    const [formError, setFormError] = React.useState("");
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [formProcessing, setFormProcessing] = React.useState(false);
    const [loader, setLoader] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
  
    const createElement = (link, type) => {
      let element = document.createElement("a");
      element.setAttribute("href", link);
      element.setAttribute("download", type);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };
    
    const navigate = useNavigate();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    // const ADD_USER = gql`mutation{
    //   UserdataCreate(data:{
    //     role:"USER",
    // 	  firstname: "${fields['firstname']}",
    //     username: "${new Date().getTime()}",
    //     lastname: "${fields['lastname']}",
    //     password: "${fields['password']}",
    //     email: "${fields['email']}",
    //     address: "${fields['address']}",
    //     state: "${fields['state']}",
    //     city: "${fields['city']}",
    //     zipcode: "${fields['zipcode']}",
    //     phonenumber: "${fields['phonenumber']}",
    //     country: "${fields['country']}",
    //     campanyid: ${fields['campanyid']},
    //   }){
    //     id
    //   }
    // }`;

    const ADD_USER = gql`mutation UserdataCreateUser($firstName: String,
      $role:String,$lastName: String,$emailId: String,$phoneNumber: String,$password: String,$companyid:Int,
      $address: String,$state: String,$city: String,$zipcode: String,$country: String
      ){
        UserdataCreateUser(firstName:$firstName,lastName:$lastName,emailId:$emailId,
        phoneNumber:$phoneNumber,password:$password,companyid:$companyid,address:$address,state:$state,city:$city,
        zipcode:$zipcode,country:$country,role:$role)
    }`;

    const UPDATE_USER = gql`mutation UserdataUpdateUser($firstName: String,
      $lastName: String,$emailId: String,$phoneNumber: String,$password: String,$companyid:Int,$id:Int,
      $address: String,$state: String,$city: String,$zipcode: String,$country: String
      ){
      UserdataUpdateUser(firstName:$firstName,lastName:$lastName,emailId:$emailId,
        phoneNumber:$phoneNumber,password:$password,companyid:$companyid,id:$id,address:$address,state:$state,city:$city,
        zipcode:$zipcode,country:$country)
    }`;

    const [addUserMutation, { data2, error2 }]  = useMutation(ADD_USER,{
      variables: {
        role:"USER",
    	  firstName: `${fields['firstname']}`,
    	  emailId: `${fields['email']}`,
        lastName: `${fields['lastname'] || ""}`,
        password: fields['password'] ? `${fields['password']}` : null,
        address: `${fields['address'] || ""}`,
        state: `${fields['state'] || ""}`,
        city: `${fields['city'] || ""}`,
        zipcode: `${fields['zipcode'] || ""}`,
        phoneNumber: `${fields['phonenumber'] || ""}`,
        country: `${fields['country'] || ""}`,
        companyid: fields['campanyid'],
      }
    });
    const [updateUserMutation, { data3, error3 }]  = useMutation(UPDATE_USER,{
      variables: {
        id: fields['id'],
        role:"USER",
    	  firstName: `${fields['firstname']}`,
    	  emailId: `${fields['email']}`,
        lastName: `${fields['lastname'] || ""}`,
        password: fields['password'] ? `${fields['password']}` : null,
        address: `${fields['address'] || ""}`,
        state: `${fields['state'] || ""}`,
        city: `${fields['city'] || ""}`,
        zipcode: `${fields['zipcode'] || ""}`,
        phoneNumber: `${fields['phonenumber'] || ""}`,
        country: `${fields['country'] || ""}`,
        companyid: fields['campanyid'],
      }
    });
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const downloadFile = (path) => {
      setLoader(true);
      const filePath = path;
      fetch(filePath)
        .then((res) => res.blob())
        .then((res) => {
          setLoader(false);
          let url = window.URL.createObjectURL(res);
          let type = res.type;
          createElement(url, type);
        })
        .catch((err) => {
          setLoader(false);
          swal("Something went wrong", {
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          console.log(err);
        });
    };
  
    // const addMoreDataHandler = (item) => {
    //   router.push({ pathname: item });
    // };
    const handleDialogClose = () => {
      setDialogOpen(false);
      if (false) {
        // router.push({ pathname: "undefined" });
      }
      setFormProcessing(false);
    };
    // function submitCompleted(data) {
    //   setFormProcessing(true);
    //   setDialogOpen(true);
    // }
  
    const [loading, setLoading] = React.useState(false);
  
    // const deletePost = (field) => {
    //   let fieldsT = {};
    //   Object.assign(fieldsT, { id: field.id, active: 0 });
    //   saveMutation({
    //     variables: {
    //       input: fieldsT,
    //     },
    //   });
    // };
    const handleValidation = (fieldname, e) => {
      let errorsT = errors;
      let formIsValid = true;
      let fieldValue = e ? (e.target ? e.target.value : e) : e;
      let updatedValue = fieldValue;

      //campanyid validations
      if (fieldname === undefined || fieldname === "campanyid") {
        if (fieldname === undefined) {
          fieldValue = fields["campanyid"] ? fields["campanyid"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["campanyid"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
          formIsValid = false;
          errorsT["campanyid"] = "Company is required";
        }
      }

      //firstname validations
      if (fieldname === undefined || fieldname === "firstname") {
        if (fieldname === undefined) {
          fieldValue = fields["firstname"] ? fields["firstname"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["firstname"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
          formIsValid = false;
          errorsT["firstname"] = "First Name is required";
        }
        else{
          if(fieldValue && !(/^[a-zA-Z]+$/i.test(fieldValue))){
            formIsValid = false;
            errorsT["firstname"] = "Please enter valid First Name";
          }
        }
      }

       //password validations
       if ((fieldname === undefined || fieldname === "password") && !localStorage.getItem("EditRowData")) {
        if (fieldname === undefined) {
          fieldValue = fields["password"] ? fields["password"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["password"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
          formIsValid = false;
          errorsT["password"] = "Password is required";
        }
      }

      //lastname validations
      if (fieldname === undefined || fieldname === "lastname") {
        if (fieldname === undefined) {
          fieldValue = fields["lastname"] ? fields["lastname"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["lastname"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        }

        if(fieldValue && !(/^[a-zA-Z]+$/i.test(fieldValue))){
          formIsValid = false;
          errorsT["lastname"] = "Please enter valid Last Name";
        }
        
      }
  
      //email validations
      if (fieldname === undefined || fieldname === "email") {
        if (fieldname === undefined) {
          fieldValue = fields["email"] ? fields["email"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["email"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
          formIsValid = false;
          errorsT["email"] = "User Email is required";
        }
        else{
          if(!utils.validateEmail(fieldValue)){
            formIsValid = false;
            errorsT["email"] = "Please enter valid email";
          }
        }
      }
  
      //phonenumber validations
      if (fieldname === undefined || fieldname === "phonenumber") {
        if (fieldname === undefined) {
          fieldValue = fields["phonenumber"] ? fields["phonenumber"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["phonenumber"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        }

        if(fieldValue && (fieldValue.length != 10 || !(/^\d+$/.test(fieldValue)))){
          formIsValid = false;
          errorsT["phonenumber"] = "Please enter valid phone number";
        }
      }
  
      //address validations
      if (fieldname === undefined || fieldname === "address") {
        if (fieldname === undefined) {
          fieldValue = fields["address"] ? fields["address"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["address"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        }
      }
  
      //state validations
      if (fieldname === undefined || fieldname === "state") {
        if (fieldname === undefined) {
          fieldValue = fields["state"] ? fields["state"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["state"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        }
      }
  
      //city validations
      if (fieldname === undefined || fieldname === "city") {
        if (fieldname === undefined) {
          fieldValue = fields["city"] ? fields["city"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["city"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        }
      }

      //zipcode validations
      if (fieldname === undefined || fieldname === "zipcode") {
        if (fieldname === undefined) {
          fieldValue = fields["zipcode"] ? fields["zipcode"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["zipcode"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        }

        if(fieldValue && (!(/^\d+$/.test(fieldValue)) || fieldValue.length < 3 || fieldValue.length > 12)){
          formIsValid = false;
          errorsT["zipcode"] = "Please enter valid Zip code";
        }
      }

      //country validations
      if (fieldname === undefined || fieldname === "country") {
        if (fieldname === undefined) {
          fieldValue = fields["country"] ? fields["country"] : "";
          updatedValue = fieldValue;
        }
  
        delete errorsT["country"];
        if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        }
      }
  
      setErrors({ ...errorsT });
      return { formIsValid, updatedValue };
    };
  
    const handleChange = (field, e) => {
      const { formIsValid, updatedValue } = handleValidation(field, e);
      let fieldsT = fields;
      fieldsT[field] = updatedValue;
      setFields({ ...fieldsT });
    };
  
    const toggleChecked = (field, e) => {
      let fieldsT = fields;
      if (fieldsT[field] === "") fieldsT[field] = true;
      else fieldsT[field] = !fieldsT[field];
      setFields({ ...fieldsT });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const { formIsValid, updatedValue } = handleValidation();
      if (formIsValid) {
        if(localStorage.getItem("EditRowData")){
          console.log("fields",fields);
          updateUserMutation().then(() => {
            swal("","User updated successfully","success");
            setTimeout(() => {
              window.location.href = "/#/admin/users";
            }, 1200);
          })
          .catch(err => {
            // swal("","Error:" + err.message,"error");
            swal("","Email already exists","warning");
          })
        }
        else{
          addUserMutation().then((resp) => {
            console.log(resp.data);
            if(resp.data.UserdataCreateUser.status == 200){
              swal("","User added successfully","success");
              setTimeout(() => {
                window.location.href = "/#/admin/users";
              }, 1200);
            }
            else{
              swal("",resp.data.UserdataCreateUser.msg,"warning");
            }
            
          }).catch(err => {
            console.error(err)
            // swal("","Error:" + err.message,"error");
            swal("","Email already exists","warning");
          })
        }
        
      }
    };
    // const deleteHandler = (item) => {
    //   deletePost({ id: item.id });
    // };
    const { classes } = props;
    return (
      <form>
      <div className="form-wrapper">
          <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <FormControl>
                        <InputLabel id="demo-dropdown-label">
                          Company
                          <span aria-hidden="true" class="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span>
                        </InputLabel>
                        <Select
                            labelId="demo-dropdown"
                            id="dropdown"
                            style={{ minWidth: 230,textAlign:"left" }}
                            value={fields.campanyid && 
                              JSON.parse(localStorage.getItem("companies")).map(i => i.value).includes(fields.campanyid) 
                              ? fields.campanyid  : 0}
                            name="companyid"
                            onChange={(e) => {handleChange("campanyid",e)}}
                            onBlur={(e) => {handleChange("campanyid",e)}}
                            input={<Input />}
                            required
                        >
                             <MenuItem key={0} value={0}>
                              Select a company
                              </MenuItem>
                            {JSON.parse(localStorage.getItem("companies")).map((obj) => {
                            return (
                                <MenuItem key={obj.key} value={obj.value}>
                                {obj.text}
                                </MenuItem>
                            );
                            })}
                        </Select>
                        </FormControl>
                        <div style={{color: "red"}} className="form-error">{errors["campanyid"]}</div>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='firstname'
                        label='First Name' 
                        type='text'
                        value={fields.firstname || ''}
                        onChange={(e) => {handleChange("firstname",e)}}
                        onBlur={(e) => {handleChange("firstname",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        required
                        /><div style={{color: "red"}} className="form-error">{errors["firstname"]}</div>
                    </Grid>

                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='lastname'
                        label='Last Name' 
                        type='text'
                        value={fields.lastname || ''}
                        onChange={(e) => {handleChange("lastname",e)}}
                        onBlur={(e) => {handleChange("lastname",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        
                        /><div style={{color: "red"}} className="form-error">{errors["lastname"]}</div>
                    </Grid>
  
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='email'
                        label='Email' 
                        type='email'
                        value={fields.email || ''}
                        onChange={(e) => {handleChange("email",e)}}
                        onBlur={(e) => {handleChange("email",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: localStorage.getItem("EditRowData"),
                        }}
                        disabled={localStorage.getItem("EditRowData") || false}
                        required
                        /><div style={{color: "red"}} className="form-error">{errors["email"]}</div>
                    </Grid>
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='password'
                        label='Password' 
                        type='password'
                        value={fields.password || ''}
                        onChange={(e) => {handleChange("password",e)}}
                        onBlur={(e) => {handleChange("password",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        required={!localStorage.getItem("EditRowData")}
                        /><div style={{color: "red"}} className="form-error">{errors["password"]}</div>
                    </Grid>
  
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='phonenumber'
                        label='Phone Number' 
                        type='text'
                        value={fields.phonenumber || ''}
                        onChange={(e) => {handleChange("phonenumber",e)}}
                        onBlur={(e) => {handleChange("phonenumber",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        /><div style={{color: "red"}} className="form-error">{errors["phonenumber"]}</div>
                    </Grid>
  
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='address'
                        label='Address' 
                        type='text'
                        value={fields.address || ''}
                        onChange={(e) => {handleChange("address",e)}}
                        onBlur={(e) => {handleChange("address",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        />
                    </Grid>
  
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='state'
                        label='State' 
                        type='text'
                        value={fields.state || ''}
                        onChange={(e) => {handleChange("state",e)}}
                        onBlur={(e) => {handleChange("state",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        />
                    </Grid>
  
                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='city'
                        label='City' 
                        type='text'
                        value={fields.city || ''}
                        onChange={(e) => {handleChange("city",e)}}
                        onBlur={(e) => {handleChange("city",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        />
                    </Grid>

                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='zipcode'
                        label='ZIP Code' 
                        type='text'
                        value={fields.zipcode || ''}
                        onChange={(e) => {handleChange("zipcode",e)}}
                        onBlur={(e) => {handleChange("zipcode",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        /><div style={{color: "red"}} className="form-error">{errors["zipcode"]}</div>
                    </Grid>

                    <Grid item xs={6} >            
                        <TextField variant="outlined"
                        id='country'
                        label='Country' 
                        type='text'
                        value={fields.country || ''}
                        onChange={(e) => {handleChange("country",e)}}
                        onBlur={(e) => {handleChange("country",e)}}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: false,
                        }}
                        />
                    </Grid>
  
                  <Grid item xs={12} >
                  <div style={{ color: "red" }}>{formError}</div>
                      <Button color="primary" variant="contained"  disabled={(loading || formProcessing)}  onClick={(e)=>{handleSubmit(e)}}
                      id='button_A828FCAF7C3F4E1BAE41A2D72318EF6F' >
          {(loading || formProcessing)  && (
              <span style={{color:"white"}}>
              <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
              /> Please Wait...
              </span>
          )}
          {!(loading || formProcessing)  && <span>{typeof(localStorage) !== 'undefined' && localStorage.getItem('EditRowData')?"Update":"Submit"}</span>}
          </Button>
          &nbsp;&nbsp;
          <Button color="primary" style={{backgroundColor: "#d9534f",borderColor: "#d43f3a"}} variant="contained"  onClick={(e)=>{
            window.location.href = "/#/admin/users";
          }}>Cancel</Button>
          <Dialog
          open={dialogOpen}
          onClose={handleDialogClose} disableEscapeKeyDown={true}
          aria-describedby="alert-dialog-description">
          <DialogContent>
          <div style={{width:"300px"}}>
          <DialogContentText id="alert-dialog-description">
             {"Success!!"}
          </DialogContentText>
          </div>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
              Ok
          </Button>
          </DialogActions>
      </Dialog>
                  </Grid>
          </Grid>
      </div>
      </form>
  );
  };
  AddUserInner.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  const CompWithStyleInner = withStyles(styles, { withTheme: true })(
    AddUserInner
  );
  function AddUser(props) {
    return <CompWithStyleInner props={props}/>;
  }
  export default AddUser;