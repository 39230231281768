// Super admin login page
import {
    Checkbox,
    Grid,
    TextField,
    FormControlLabel,
    Paper,
    Button,
    Typography,
  } from '@material-ui/core';

import { Link } from 'react-router-dom';
import axios from "axios";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import {config} from "../../config";
import { saveUser } from '../../redux/reducers/userSlice';

export default function UserLogin(){
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(state => state.user.user);

    useEffect(() => {
        if(user){
            navigate("/user-form");
        }
    });

    const handleLogin = () => {
        
        const email = document.getElementById("admin_email").value;
        const password = document.getElementById("admin_password").value;
        if(email == "" || password == ""){
            swal("","Email and password are required","warning");
            return;
        }
        axios.post(`${config.baseURL}/login/?email=${email}&password=${password}`)
        .then(res => {
            console.log("login response",res.data);
            if(res.data.statusCode > 0){
                swal("","Please enter Valid Email or Password.","error");
            }
            else{
                if(res.data.role == "SUPERADMIN"){
                    swal("","This user role cannot login from this panel","warning");
                    return;
                }
                // swal("","Login success","success")
                setTimeout(() => {
                    dispatch(saveUser({...res.data}));
                    navigate("/user-form");
                },200);
            }
        })
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    }

    return (
        <div className='login-page-wrapper user-login-page'>
            
            <div className="logo-wrapper">
                <Link to='/' className="logo-link">
                    <img src="../../../promedwise-logo.png" alt="Promedwise Logo" width="200px"/>
                </Link>
            </div>
            
            <Paper>
                <Typography style={{fontSize:25}} align='center'>Login</Typography> <br />
                <Grid
                container
                spacing={3}
                direction={'column'}
                justify={'center'}
                alignItems={'center'}
                >
                    <Grid item lg={12}>
                        <TextField onKeyDown={handleKeyDown} id='admin_email' label="Email"></TextField>
                    </Grid>
                    <Grid item lg={12}>
                        <TextField onKeyDown={handleKeyDown} id='admin_password' label="Password" type={'password'}></TextField>
                    </Grid>

                    <Grid item lg={12} className="formControl__wrapper">
                        {/* <FormControlLabel control={<Checkbox checked={true} />} label="Remember me" /> */}
                        <Link to="/forgot-password">Forgot Password?</Link>
                    </Grid>
            
                    <Grid item xs={12} style={{textAlign: "center"}}>
                        <Button onClick={handleLogin} variant='contained' color="primary" fullWidth> Login </Button>
                    </Grid>
                </Grid>
            </Paper>

            <div className='footer-link'>
                <Link to="">www.promedwise.com</Link>
            </div>
        </div>
    );
}